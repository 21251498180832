/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import {
  Button, Form, Row, Col, InputGroup,
} from 'react-bootstrap';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { LeafletMap } from '../LeafletMap';
import { getUnauthenticatedWorkMapInfo } from '../../scripts/mapScripts';
import {
  googleAddressSearch,
  googleReverseGeocode,
  getPlaceDetailsAsJson,
} from '../../scripts/addressScripts';
import {
  getSuburbs,
} from '../../scripts/joburg_water/addressScripts';
import '../../styles/AddressInput.scss';
import { apiUrlWithHttps } from '../../constants/constantsGlobal';

export class AddressInput extends React.Component {
  constructor(props) {
    super(props);
    const {
      onResultFound,
      updateEntries,
      updateReqEntries,
      requiredMissing,
      subscriberId,
      readOnly,
    } = this.props;
    this.onResultFound = onResultFound;
    this.updateEntries = updateEntries;
    this.updateReqEntries = updateReqEntries;
    this.requiredMissing = requiredMissing;
    this.readOnly = readOnly;
    this.state = {
      searchResults: [],
      suburbResults: [],
      showSuburbSearch: false,
      center: {
        lat: 0,
        lng: 0,
      },
      subscriberId,
    };
  }

  handleMarkerChange = async (center) => {
    const result = await googleReverseGeocode(center.lat, center.lng);
    const temp = {
      streetNumber: result.address.streetNumber || '',
      streetName: result.address.streetName || '',
      suburb: result.address.suburb || '',
      city: result.address.city || '',
      postalCode: result.address.postalCode || '',
      latitude: result.position.lat || 0.0,
      longitude: result.position.lon || 0.0,
    };
    this.onResultFound(temp);
    this.setState({
      center:
      {
        lat: result.position.lat,
        lon: result.position.lon,
      },
    });
  }

  updateEntries = (name, value) => this.updateEntries(name, value);

  updateReqEntries = (name, value) => this.updateReqEntries(name, value);

  validStyle = (val) => {
    if (val) { return { color: 'red' }; } return null;
  };

  componentDidMount = async () => {
    const { subscriberId } = this.state;
    const res = await getUnauthenticatedWorkMapInfo(subscriberId);
    this.setState({
      center: {
        lat: res.defaultLat,
        lng: res.defaultLong,
      },
    });
  }

  render() {
    const {
      entries: {
        streetNumber,
        streetName,
        suburb,
        city,
        postalCode,
        standNumber,
        building,
        unitNumber,
        description,
      },
      requiredMissing,
      readOnly,
      page,
    } = this.props;
    const {
      showSuburbSearch,
      center,
      searchResults,
      suburbResults,
    } = this.state;

    const handleSuburbSearch = async (query) => {
      if (query.length > 0) {
        const newresults = await getSuburbs(query);
        this.setState({
          suburbResults: newresults,
        });
      }
    };

    const handleSearchChange = async (query) => {
      if (query.length > 0) {
        const response = await googleAddressSearch(
          query,
          'ZA',
        );
        const predictions = response.predictions || [];
        const newresults = predictions.map((p) => ({ label: p.description, value: p.placeId }));
        this.setState({
          searchResults: newresults,
        });
      }
    };

    function debounce(func, delay) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    }

    const debouncedSearch = debounce(handleSearchChange, 500);

    const debouncedSuburbSearch = debounce(handleSuburbSearch, 500);

    return (
      <div id="AddressInput" className="section">
        <Form.Group className="formLabels">
          <Form.Label className="largeLabel">{page === 'profile' ? 'Home Address Information' : 'Fault Location Information'}</Form.Label>
        </Form.Group>
        {!readOnly && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
            `}
          >
            <Form.Group className="formLabels">
              <Form.Label
                className="smallLabel"
              >
                Please use the search bar above the map to search for your address.
              </Form.Label>
            </Form.Group>
            <div className="location-search" style={{ margin: '0px' }}>
              <Autocomplete
                disablePortal
                disableClearable
                id="combo-box"
                options={searchResults || []}
                onChange={async (event, newValue) => {
                  console.log(newValue);
                  const result = await getPlaceDetailsAsJson(newValue.value);
                  if (result) {
                    const temp = {
                      streetNumber: result.address.streetNumber || '',
                      streetName: result.address.streetName || '',
                      suburb: result.address.suburb || '',
                      city: result.address.city || '',
                      postalCode: result.address.postalCode || '',
                      latitude: result.position.lat || 0.0,
                      longitude: result.position.lon || 0.0,
                    };
                    this.onResultFound(temp);
                    this.setState({
                      center:
                      {
                        lat: result.position.lat,
                        lon: result.position.lon,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    label=""
                    onChange={(event) => {
                      debouncedSearch(event.target.value);
                    }}
                  />
                )}
              />
            </div>
            <Col md={12} css={css`padding: 0px;`}>
              <Form.Group className="mapCanvasWrapper">
                <LeafletMap
                  center={center}
                  onCenterChange={(centered) => this.handleMarkerChange(centered)}
                  url={`${apiUrlWithHttps}addressmanager/googleMapTiles/{z}/{x}/{y}.png?type=roadmap`}
                />
              </Form.Group>
              <Form.Text muted id="helperText">
                You can drag and drop the pin on the map to give us the exact location.
              </Form.Text>
            </Col>
          </div>
        )}
        <Form.Group>
          <Row className="formInputs">
            {/* STREET NUMBER */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label
                  className="smallLabel"
                  style={this.validStyle(requiredMissing.streetNumber === true)}
                >
                  Street Number
                  {' '}
                  {!readOnly && <span css={css`color: red`}>*</span>}
                </Form.Label>
              </Form.Group>
              <Form.Control
                name="streetNumber"
                type="text"
                required
                isInvalid={
                  requiredMissing.streetNumber === true
                }
                value={streetNumber}
                onChange={
                  (e) => {
                    this.updateReqEntries(e.target.name, e.target.value);
                  }
                }
                disabled={readOnly}
              />
            </Col>
            {/* STREET NAME */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label
                  className="smallLabel"
                  style={this.validStyle(requiredMissing.streetName === true)}
                >
                  Street Name
                  {' '}
                  {!readOnly && <span css={css`color: red`}>*</span>}
                </Form.Label>
              </Form.Group>
              <Form.Control
                name="streetName"
                type="text"
                required
                isInvalid={
                  requiredMissing.streetName === true
                }
                value={streetName}
                onChange={(e) => {
                  this.updateReqEntries(e.target.name, e.target.value);
                }}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row className="formInputs">
            {/* SUBURB */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label
                  className="smallLabel"
                  style={this.validStyle(requiredMissing.suburb === true)}
                >
                  Suburb
                  {' '}
                  {!readOnly && <span css={css`color: red`}>*</span>}
                </Form.Label>
              </Form.Group>
              <InputGroup>
                {showSuburbSearch ? (
                  <div
                    className="location-search"
                    css={css`
                      margin-bottom: 0px;
                      width: calc(100% - 43px);
                      margin 0px;

                      fieldset {
                        ${requiredMissing.suburb === true && 'border-color: red;'}
                      }
                    `}
                  >
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="combo-box"
                      options={suburbResults || []}
                      onChange={async (event, newValue) => {
                        console.log(newValue);
                        this.onResultFound({
                          streetNumber: '',
                          streetName: '',
                          city: '',
                          postalCode: '',
                          latitude: 0.0,
                          longitude: 0.0,
                          suburb: newValue.value,
                          orgUnitCode: newValue.attributes.code,
                        });
                        this.setState({
                          showSuburbSearch: false,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          label=""
                          onChange={(event) => {
                            debouncedSuburbSearch(event.target.value);
                          }}
                        />
                      )}
                    />
                  </div>
                )
                  : (
                    <Form.Control
                      name="suburb"
                      type="text"
                      required
                      isInvalid={requiredMissing.suburb === true}
                      value={suburb}
                      onChange={
                        (e) => {
                          const sanitizedValue = e.target.value.replace(/[0-9]/g, '');
                          this.updateReqEntries(e.target.name, sanitizedValue);
                        }
                      }
                      disabled
                    />
                  )}
                {!readOnly
                && (
                <InputGroup.Append>
                  <Button
                    onClick={() => {
                      this.setState({ showSuburbSearch: !showSuburbSearch });
                    }}
                    variant="outline-primary"
                  >
                    <SearchIcon />
                  </Button>
                </InputGroup.Append>
                )}
              </InputGroup>
              {!readOnly
                && (
                <Form.Text muted id="helperText">
                  If you cannot find your address please search
                  for your suburb here by clicking on the search icon.
                </Form.Text>
                )}
            </Col>
            {/* CITY */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label className="smallLabel">City</Form.Label>
              </Form.Group>
              <Form.Control
                name="city"
                type="text"
                value={city}
                onChange={(e) => {
                  this.updateEntries(e.target.name, e.target.value);
                }}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row className="formInputs">
            {/* POSTAL CODE */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label className="smallLabel">Postal Code</Form.Label>
              </Form.Group>
              <Form.Control
                name="postalCode"
                type="number"
                value={postalCode}
                onChange={(e) => {
                  this.updateEntries(e.target.name, e.target.value);
                }}
                disabled={readOnly}
              />
            </Col>
            {/* STAND NUMBER */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label className="smallLabel">Stand Number</Form.Label>
              </Form.Group>
              <Form.Control
                name="standNumber"
                type="text"
                value={standNumber}
                onChange={(e) => {
                  this.updateEntries(e.target.name, e.target.value);
                }}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row className="formInputs">
            {/* UNIT NUMBER */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label className="smallLabel">Unit Number</Form.Label>
              </Form.Group>
              <Form.Control
                name="unitNumber"
                type="text"
                value={unitNumber}
                onChange={(e) => {
                  this.updateEntries(e.target.name, e.target.value);
                }}
                disabled={readOnly}
              />
            </Col>
            {/* BUILDING */}
            <Col md={6}>
              <Form.Group className="formLabels">
                <Form.Label className="smallLabel">Building/Complex/Estate</Form.Label>
              </Form.Group>
              <Form.Control
                name="building"
                type="text"
                value={building}
                onChange={(e) => {
                  this.updateEntries(e.target.name, e.target.value);
                }}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row className="formInputs">
            {/* DESCRIPTION/ADDITIONAL INFO */}
            <Col md={12}>
              <Form.Group className="formLabels">
                <Form.Label className="smallLabel">
                  Additional Location details (eg. Closest Cross Street)
                </Form.Label>
              </Form.Group>
              <Form.Control
                name="description"
                as="textarea"
                rows={3}
                value={description}
                onChange={
              (e) => {
                this.updateReqEntries(e.target.name, e.target.value);
              }
            }
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Form.Group>
      </div>
    );
  }
}
