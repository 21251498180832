import React, { useState, useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Table } from '../../components/Table';
import { Context } from '../../functions/fidelity_sf/context';
import {
  getPortalCustomerDetails,
} from '../../functions/apiCalls';

const ViewTickets = () => {
  document.title = 'Tickets | Fidelity Secure Fire Customer Portal';
  const [filters, setFilters] = useState();

  useEffect(() => {
    const setTable = async () => {
      const customerDetails = await getPortalCustomerDetails().then((res) => res.json());
      const newFilters = [
        {
          property: 'childCustomers',
          value: customerDetails.id,
          options: [],
        },
        {
          property: 'docType',
          value: 'WorkOrderRequest',
          options: [],
        },
        {
          property: 'workRequestType.custom5',
          value: '1',
          options: [],
        },
      ];
      setFilters(newFilters);
    };
    setTable();
  }, []);

  console.log('filters', filters);
  console.log('table', Table.filters);

  return (
    <>
      {filters
        ? (
          <Table
            Context={Context}
            title="Tickets"
            tableModelClass="WorkOrderRequest"
            id="workorderrequest"
            columnView="CustomerPortalView"
            filters={filters}
            sorts={{
              property: 'dateCreated',
              order: 'DESC',
            }}
          />
        ) : (
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        )}
    </>
  );
};

export default ViewTickets;
