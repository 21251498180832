/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Row, Col, Card, Form, Button, Alert,
} from 'react-bootstrap';
import { Dimmer, Loader } from 'semantic-ui-react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Context } from '../../functions/fidelity_sf/context';
import {
  getPortalCustomerDetails,
  updatePortalUserDetails,
} from '../../functions/apiCalls';

/**
 * Page for viewing/changing the customer details
 */
const CustomerDetailsPage = () => {
  const { labels } = useContext(Context);

  const [customerDetails, setCustomerDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [submitResponse, setSubmitResponse] = useState();
  const history = useHistory();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: customerDetails });

  useEffect(() => {
    document.title = 'Customer Account Details | Secure Fire Portal';

    async function fetchData() {
      setLoading(true);
      const response = await getPortalCustomerDetails().then((res) => res.json());
      console.log('UPDATE:');
      setCustomerDetails(response);
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => reset(customerDetails), [customerDetails]);

  const onSubmit = (data) => {
    console.log(data);

    const requestBody = {
      ...data.user,
    };

    async function postData() {
      setPosting(true);
      const response = await updatePortalUserDetails(requestBody).then((res) => res.json());
      setSubmitResponse(response);
      setPosting(false);
      if (response.result === 'SUCCESS') {
        history.push('/home');
      }
    }
    postData();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {submitResponse && (
        <Alert
          variant={submitResponse.result === 'SUCCESS' ? 'success' : 'danger'}
          dismissible
          onClose={() => setSubmitResponse(null)}
        >
          {submitResponse.result === 'SUCCESS'
            ? 'Successfully updated your details.'
            : submitResponse.errorMessage || 'Error: Could not update your details.'}
        </Alert>
      )}
      <Form onSubmit={handleSubmit(onSubmit)} css={css`width: 700px`}>
        <Card css={css`padding: 40px 24px;`}>
          <Dimmer inverted active={loading}>
            <Loader>Loading your details</Loader>
          </Dimmer>
          <div
            css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
          `}
          >
            <div
              role="button"
              tabIndex={0}
              css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              font-weight: 650;
              font-size: 12px;
              line-height: 14px;
              display: flex;
              align-items: center;
              color: #306856;
              cursor: pointer;

              svg {
                margin-right: 5px;
              }

              :hover {
                color: #F95;
              }
            `}
              onClick={() => { history.push('/home'); }}
            >
              <ArrowBackIosIcon />
              <div css={css`opacity: 0.5;`}>Back</div>
            </div>
            <div css={css`
              background-image: url("/fidelity_sf/logo_small.png");
              background-blend-mode: overlay;
              background-size: cover;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              width: 159.6px;
              height: 38.4px;
            `}
            />
          </div>
          <div
            css={css`
              color: #262626;
              font-size: 18px;
              font-weight: 500;
              margin-bottom: 16px;
            `}
          >
            Edit Your details
          </div>
          <Card.Body
            className="ml-md-5 mr-md-5"
            css={css`
              padding: 0px;
              margin: 0px !important;

              .row {
                height: fit-content;
                margin: 0px;
                gap: 16px;

                @media (min-width: 768px) {
                  .col-md-6 {
                    flex: 0 0 calc(50% - 8px);
                    max-width: calc(50% - 8px);
                  }
                }

                .col-sm-12 {
                  padding: 0px;

                  .form-group {
                    margin: 0px !important;
                  }
                }
              }
            `}
          >
            <h4
              css={css`
                color: #1A6B54;
                width: 100%;
                padding-bottom: 8px;
                border-bottom: 1px solid #1A6B54;
                padding: 12px 0px;
              `}
            >
              Customer Account Details
            </h4>
            <Row css={css`margin-bottom: 16px !important;`}>
              <Col md="12" sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>{labels['User.fullName']}</Form.Label>
                  <Controller
                    control={control}
                    name="user.fullName"
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={errors.user?.fullName}
                        readOnly
                        placeholder={labels['User.fullName']}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.user?.fullName?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row css={css`margin-bottom: 16px !important;`}>
              <Col md="12" sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>{labels['user.username']}</Form.Label>
                  <Controller
                    control={control}
                    name="user.username"
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={errors.user?.username}
                        readOnly
                        placeholder={labels['user.username']}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.createdDate?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row css={css`margin-bottom: 16px !important;`}>
              <Col md="12" sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>{labels['user.password']}</Form.Label>
                  <Controller
                    control={control}
                    name="user.password"
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={errors.user?.password}
                        type="password"
                        placeholder={labels['user.password']}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.user?.password?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="12" sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>{labels['user.passwordHint']}</Form.Label>
                  <Controller
                    control={control}
                    name="user.passwordHint"
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={errors.user?.passwordHint}
                        placeholder={labels['user.passwordHint']}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.user?.passwordHint?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <h4
              css={css`
                color: #1A6B54;
                width: 100%;
                padding-bottom: 8px;
                border-bottom: 1px solid #1A6B54;
                padding: 12px 0px;
                margin: 16px 0px;
              `}
            >
              Contact Details
            </h4>
            <Row css={css`margin-bottom: 16px !important;`}>
              <Col md="12" sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>{labels['User.mobileNumber']}</Form.Label>
                  <Controller
                    control={control}
                    name="user.mobileNumber"
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={errors.mobileNumber}
                        placeholder={labels['User.mobileNumber']}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobileNumber?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row css={css`margin-bottom: 16px !important;`}>
              <Col md="12" sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>{labels['user.phoneNumber']}</Form.Label>
                  <Controller
                    control={control}
                    name="user.phoneNumber"
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={errors.phoneNumber}
                        placeholder={labels['user.phoneNumber']}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.officeNumber?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="12" sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>{labels['user.email']}</Form.Label>
                  <Controller
                    control={control}
                    name="user.email"
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={errors.email}
                        placeholder={labels['user.email']}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.emailAddress?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer
            css={css`
              margin-top: 24px;
              padding: 24px 0px 0px 0px;
            `}
          >
            <Row
              css={css`
                display: flex;
                align-content: center;
                margin: 0px !important;
                gap: 16px;
                width: 100%;
                height: 45px !important;
              `}
              className="justify-content-around"
            >
              <Link
                to="/home"
                css={css`
                  height: fit-content;
                  width: calc(30% - 8px); 
                `}
              >
                <Button
                  css={css`
                    height: 45px;
                    width: 100%;
                    border: 1px solid #D9D9D9;
                    background-color: #ffffff;
                    color: #262626;
                  `}
                  variant="secondary"
                >
                  Cancel
                </Button>
              </Link>
              <Button
                css={css`
                  height: 45px;
                  width: calc(70% - 8px);
                  border: 1px solid #1A6B54;
                  background-color: #1A6B54;
                  color: #ffffff;

                  :hover {
                    background-color: #F95 !important;
                    border-color: #F95 !important;
                    box-shadow: none !important;
                  }

                  :focus {
                    background-color: #F95 !important;
                    border-color: #F95 !important;
                    box-shadow: none !important;
                  }

                  :active {
                    background-color: #F95 !important;
                    border-color: #F95 !important;
                    box-shadow: none !important;
                  }
                `}
                disabled={posting}
                active={posting}
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </Row>
          </Card.Footer>
        </Card>
      </Form>
    </>
  );
};

export default CustomerDetailsPage;
