import React from 'react';
import { useHistory } from 'react-router-dom';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import {
  HomeContainer,
  OuterContainer,
  InnerContainer,
  SubscriberImage,
  StyleContainer,
  StyleHr,
  Footer,
  BoxContainer,
  Box,
  ContactInfoContainer,
  ContactInfo,
  Title,
  Header,
} from '../../pages/myciti/home/home.styled';
import SlideIn from './slidein';

const HomePage = () => {
  const history = useHistory();

  return (
    <HomeContainer>
      <OuterContainer>
        <div />
        <InnerContainer>
          <StyleContainer>
            <SlideIn settings={{
              marginRight: 0,
              width: '100%',
              from: { marginRight: -450 },
              config: { mass: 1, tension: 300, friction: 30 },
            }}
            >
              <Header>
                <div style={{ width: '68px', height: '21px' }} />
                <SubscriberImage />
              </Header>
              <Title>
                Online Card Services
              </Title>
              <BoxContainer>
                <Box onClick={() => { history.push('/lostorstolen'); }}>
                  <CreditCardOffOutlinedIcon />
                  <h2>
                    Report a Lost or Stolen Card
                  </h2>
                </Box>
                <Box onClick={() => { history.push('/refund'); }}>
                  <AccountBalanceWalletOutlinedIcon />
                  <h2>
                    Apply for a Refund
                  </h2>
                </Box>
              </BoxContainer>
            </SlideIn>
          </StyleContainer>
          <StyleHr />
          <ContactInfoContainer>
            <ContactInfo>
              <div>For help visit</div>
              <a href="https://www.myciti.org.za" target="_blank" rel="noreferrer">www.myciti.org.za</a>
            </ContactInfo>
          </ContactInfoContainer>
        </InnerContainer>
        <Footer>
          <div
            style={{ paddingRight: '5px' }}
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net');
            }}
          >
            Powered by Forcelink&trade;
            {' '}
            {new Date().getFullYear()}
            {' '}
            |
          </div>
          <div
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net/privacy-policy');
            }}
          >
            Privacy Policy
          </div>
        </Footer>
      </OuterContainer>
    </HomeContainer>
  );
};

export default HomePage;
