import { apiUrl, apiUrlWithHttps, apiBaseUrl } from '../constants/constantsGlobal';

const headers = {
  // Accept: "application/json, text/javascript, q=0.01",
  'Content-Type': 'application/problem+json',
};

export const authenticationCheck = (res) => {
  if (res.status === 401) {
    // This should take one to the root of the custom portal
    console.log('Looks like your not logged in navigating you home, bye!');
    const firstRoot = window.location.pathname.split('/')[1];
    window.location.href = `${window.location.protocol}//${window.location.host}/${firstRoot}/logout`;
  }
};

export const fetchApi = (endpoint, method, rest) => {
  console.log('endpoint', endpoint);
  const anonymous = localStorage.getItem('anonymous');
  const subscriberId = localStorage.getItem('subscriberId');
  const apiKey = process.env.REACT_APP_USER_API_KEY;
  return fetch(`https://${endpoint}`, {
    method,
    headers: {
      ...headers,
      ...(anonymous && apiKey !== undefined ? {
        apiKey,
        subscriberId,
      } : {}),
    },
    credentials: 'include',
    ...rest,
  }).then((res) => {
    authenticationCheck(res);
    return res;
  });
};

export const getUserRoles = async () => fetchApi(`${apiUrl}usermanager/getUserRoles`, 'GET', {
  languageCode: 'en',
})
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const standardError = (
  e,
  errorOpening = 'Error making this request',
) => {
  console.error(e);
  let errMsg = e.responseText || e.message;
  if (!errMsg || errMsg.length === 0) { errMsg = 'unknown error, you are possibly not connected.'; }
  alert(`${errorOpening}: ${errMsg}`);
  return false;
};

export const byClassAndProp = async (className, propertyName, subscriberId) => fetchApi(
  `${apiUrl}customerportalmanager/findRootItemsByClassAndProperty?className=${className}&propertyName=${propertyName}${subscriberId !== undefined ? `&subscriberId=${subscriberId}` : ''}&currentItemId=0&queryString=`,
  'GET',
  { credentials: 'include' },
);

export const findLabelValuesByClassAndPropertyDWR = async (
  className, propertyName, currentEntryId, callTakeForm, docId,
) => fetchApi(
  `${apiUrl}referencelistmanager/findLabelValuesByClassAndPropertyDWR?className=${className}&propertyName=${propertyName}&currentEntryId=${currentEntryId}&callTakeForm=${callTakeForm}&docId=${docId}`,
  'GET',
  { credentials: 'include' },
);

export const findLabelValuesByClassAndPropertyForCustomPortal = async (
  className, propertyName, currentEntryId, docId,
) => fetchApi(
  `${apiUrl}referencelistmanager/findLabelValuesByClassAndPropertyForCustomPortal?className=${className}&propertyName=${propertyName}&currentEntryId=${currentEntryId}&docId=${docId}`,
  'GET',
  { credentials: 'include' },
);

export const getUpperSelectionTree = async (
  currentEntryId,
) => fetchApi(
  `${apiUrl}referencelistmanager/getUpperSelectionTree?currentEntryId=${currentEntryId}`,
  'GET',
  { credentials: 'include' },
);

export const setProperty = async (
  className, property, id, value,
) => fetchApi(
  `${apiUrl}customerportalmanager/setProperty?className=${className}&property=${property}&id=${id}&value=${value}`,
  'GET',
  { credentials: 'include' },
);

export const findLabelValuesByClassAndProperty = async (
  className, propertyName, currentEntryCode, callTakeForm, useCodeForValue, docId,
) => fetchApi(
  `${apiUrl}referencelistmanager/findLabelValuesByClassAndProperty?className=${className}&propertyName=${propertyName}&currentEntryCode=${currentEntryCode}&callTakeForm=${callTakeForm}&useCodeForValue=${useCodeForValue}&docId=${docId}`,
  'GET',
  { credentials: 'include' },
);

export const getAllLabels = async () => fetchApi(`${apiUrl}subscribercustomlabelsmanager/getAllLabelsREST`, 'GET', {
  languageCode: 'en',
})
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const isAuthenticated = async () => fetchApi(`${apiUrl}usermanager/isSessionAuthenticated`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .catch((e) => {
    console.error('Comms error:', e);
    return false;
  });

export const logout = async () => fetchApi(`${apiUrl}sessionutilsmanager/logout`, 'GET', {
  credentials: 'include',
}).catch((e) => {
  console.error('Comms error:', e);
  return false;
});

export const getPortalCustomerDetails = () => fetchApi(`${apiUrl}customerportalmanager/getPortalCustomerDetails`, 'GET', {
  async: true,
  credentials: 'include',
});

export const updatePortalCustomerDetails = (customerDetailsObject) => fetchApi(`${apiUrl}customermanager/updatePortalCustomerDetails`, 'POST', {
  body: JSON.stringify(customerDetailsObject),
});

export const updatePortalUserDetails = (customerDetailsObject) => fetchApi(`${apiUrl}customermanager/updatePortalCustomerUserDetails`, 'POST', {
  body: JSON.stringify(customerDetailsObject),
});

export const getProfileCustomerTypes = async (subscriberId) => byClassAndProp('Customer', 'customerType', subscriberId)
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const getWorkOrder = (code) => fetchApi(
  `${apiUrl}customerportalmanager/getWorkOrder${
    code ? `?code=${code}` : ''
  }`,
  'GET',
  { credentials: 'include' },
);

export const findLabelValueByClassAndProperty = (className, propertyName) => fetchApi(`${apiUrl}referencelistmanager/findLabelValueByClassAndProperty?className=${className}&propertyName=${propertyName}`,
  'GET',
  { credentials: 'include' });

export const getAllActiveUserGroupsDWR = () => fetchApi(`${apiUrl}usermanager/getAllActiveUserGroupsDWR`,
  'GET',
  { credentials: 'include' });

export const deleteDocument = (docId, entityId) => fetchApi(`${apiUrl}customerportalmanager/deleteDocument?docId=${docId}&entityId=${entityId}`,
  'GET',
  { credentials: 'include' });

export const getDocumentsDWRForClassAndId = (modelClass, id) => fetchApi(`${apiUrl}customerportalmanager/getDocumentsDWRForClassAndId?modelClass=${modelClass}&id=${id}`,
  'GET',
  { credentials: 'include' });

export const uploadOrEditDocument = (doc) => fetch(`https://${apiUrl}customerportalmanager/uploadOrEditDocument`, {
  method: 'POST',
  body: doc,
  credentials: 'include',
});

export const getNotesForWO = (id) => fetchApi(`${apiUrl}customerportalmanager/getNotesForWO?id=${id}`,
  'GET',
  { credentials: 'include' });

export const getWorkRequestTypes = () => fetchApi(
  `${apiUrl}referencelistmanager/findCodeDescriptionByClassAndProperty?className=WorkOrderRequest&propertyName=workRequestType`,
  'GET',
  { credentials: 'include' },
);

export const getWorkRequestSubtypes = (parentCode) => fetchApi(
  `${apiUrl}referencelistmanager/findCodeDescriptionByParentCodeClassProperty?className=WorkOrderRequest&propertyName=workRequestType&code=${parentCode}`,
  'GET',
  { credentials: 'include' },
);

export const getWorkRequest = (code) => fetchApi(
  `${apiUrl}customerportalmanager/getWorkRequest${
    code ? `?code=${code}` : ''
  }`,
  'GET',
  { credentials: 'include' },
);

export const getWorkRequestStatus = (id) => fetchApi(`${apiUrl}workorderrequestmanager/getStatusDWR?id=${id}`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const getWorkRequestNextStatuses = (statusId, objectId) => fetchApi(
  `${apiUrl}statusreferencemanager/getNextStatusesDWR?className=WorkOrderRequest&statusId=${statusId}&objectId=${objectId}`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const genericChangeStatus = (statusId, objectId) => fetchApi(
  `${apiUrl}statusreferencemanager/genericChangeStatus?className=WorkOrderRequest&statusId=${statusId}&objectId=${objectId}&reason=`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const getCustomLookup1Values = () => fetchApi(
  `${apiUrl}custommanager_servest_is/getWRcustomLookup1Values`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const getCustomLookup1ValuesSsg = () => fetchApi(
  `${apiUrl}custommanager_ssg_fac/getWRcustomLookup1Values`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const getCustomLookup1ValuesBedworth = () => fetchApi(
  `${apiUrl}custommanager_bedworth/getWRcustomLookup1Values`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const getFidelityCustom5WorkRequestTypes = () => fetchApi(
  `${apiUrl}custommanager_fidelity/getOnlyCustom5WRType`,
  'GET',
  { credentials: 'include' },
);

// This consumes form data to add an image
// @FormDataParam("file") InputStream uploadedInputStream,
// @FormDataParam("file") FormDataContentDisposition fileDetail,
// @FormDataParam("workRequestId") long workRequestId)
export const addWorkRequestAttachment = (formData, subscriberId) => fetch(`${apiUrlWithHttps}smartcitymanager/addWorkRequestAttachment`, {
  method: 'POST',
  async: false,
  credentials: 'include',
  body: formData,
  processData: false,
  contentType: false,
  headers: {
    credentials: 'include',
    subscriberId,
  },
})
  .then((res) => {
    authenticationCheck(res);
    return res.json();
  })
  .catch((e) => {
    console.error('Comms error:', e);
    return [];
  });

export const imageUrl = (id, subscriberId) => `${apiUrl}modal/viewimagefile.html?id=${id}&subscriberId=${subscriberId}`;

export const postWorkRequest = (workRequestObject) => fetch(`${apiUrlWithHttps}customerportalmanager/postWorkRequest`, {
  method: 'POST',
  async: true,
  body: JSON.stringify(workRequestObject),
  credentials: 'include',
  headers: {
    Accept: 'application/json, text/javascript; q=0.01',
    'Content-Type': 'application/problem+json',
  },
})
  .then((res) => {
    authenticationCheck(res);
    return res;
  })
  .catch((e) => e);

export const getWorkRequestNoteTypes = () => fetchApi(`${apiUrl}referencelistmanager/findAvailableNoteTypesDWR`, 'GET', {
  credentials: 'include',
})
  .then((res) => res.json())
  .catch((e) => console.error(e));

export const addNoteToWorkRequest = (id, noteTypeId, text) => fetchApi(`${apiUrl}workorderrequestmanager/addNote?id=${id}&noteTypeId=${noteTypeId}&text=${text}`, 'GET', {
  credentials: 'include',
})
  .then()
  .catch((e) => console.error(e));

export const getCustomersForParent = (parentCustomerId) => fetchApi(`${apiUrl}customerportalmanager/getCustomersForParent?parentCustomerId=${parentCustomerId}`, 'GET', {
  credentials: 'include',
})
  .then()
  .catch((e) => console.error(e));

export const exportTableData = (limit) => fetchApi(`${apiUrl}customerportalmanager/exportTableData`, 'POST', {
  credentials: 'include',
  body: JSON.stringify(limit),
  headers: {
    'Content-Type': 'application/json',
  },
})
  .then((response) => response.json())
  .catch((e) => console.error(e));

export const getExportProgress = () => fetchApi(`${apiUrl}jmesaquerymanager/getExportProgress`, 'GET', {
  credentials: 'include',
})
  .then((response) => response.json())
  .catch((e) => console.error('Error fetching exported files:', e));

export const getExportedFiles = () => fetchApi(`${apiUrl}jmesaquerymanager/getExportedFiles`, 'GET', {
  credentials: 'include',
})
  .then((response) => response.json())
  .catch((e) => console.error('Error fetching exported files:', e));

export const getExportFileDownload = async (url) => {
  const fullUrl = apiBaseUrl + url;
  try {
    const response = await fetchApi(fullUrl, 'GET', {
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.blob();
  } catch (e) {
    console.error('Error fetching exported file:', e);
    throw e;
  }
};
