/**
 * @author Matthew Castle
 *
 * Contains a KPI element
 */

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { memo, useState, useEffect } from 'react';
import { getKPIData } from '../../scripts/fidelity_sf/globalScripts';

const KPI = memo(({ title, color, query }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const val = await getKPIData(query);
      setValue(val);
    };
    fetchData();
  }, []);

  return (
    <div
      className="kpi-card"
      css={css`border-left: 4px solid ${color};`}
    >
      <div className="kpi-value">
        {value !== '' ? value : '-'}
      </div>
      <div className="kpi-title">
        {title}
      </div>
    </div>
  );
});

export default KPI;
