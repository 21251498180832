import { apiUrl } from '../../constants/constantsGlobal';
import { fetchApi } from '../../functions/apiCalls';

export const getSuburbs = async (searchString) => fetchApi(
  `${apiUrl}custommanager_joburg_water/getSuburbs?searchString=${encodeURIComponent(
    searchString,
  )}`,
  'GET',
  { credentials: 'include' },
)
  .then((response) => response.json())
  .then((resp) => resp)
  .catch((e) => {
    console.error(e);
    return { predictions: [] };
  });
