/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import {
  Button, Form, Card,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { CircularProgress as Spinner } from '@material-ui/core';
import * as login from '../../scripts/loginScripts';
import '../../styles/fidelity_sf/login.scss';

export class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitting: false,
      loginDone: false,
      passwordDisplay: 'password',
    };
  }

  handleLogin = () => {
    console.log('handling login');
    const { onLogin } = this.props;
    const {
      username,
      password,
    } = this.state;
    this.setState({ submitting: true });
    onLogin(
      username,
      password,
      (submitting, loginDone) => this.setState({ submitting, loginDone }),
    );
  };

  render() {
    const {
      username, submitting, loginDone, passwordDisplay,
    } = this.state;
    const { subscriberId } = this.props;

    return (
      <div
        id="loginFormContainer"
        css={css`
          background-color: #ffffff;
          box-shadow: none !important;
        `}
      >
        <div className="new-login-logo-container">
          <img src="fidelity_sf/logo_small.png" className="new-login-logo-image" alt="Fidelity FS Portal Logo" />
        </div>
        <div className="new-login-form-container">
          <div id="loginFormCard" className="text-center">
            <Card.Body>
              <Form>
                <Form.Group css={css`margin-bottom: 16px;`}>
                  <Material.TextField
                    name="username"
                    fullWidth
                    label="Username"
                    variant="outlined"
                    onChange={(event) => this.setState({ username: event.target.value })}
                  />
                </Form.Group>
                <Form.Group css={css`margin-bottom: 40px;`}>
                  <Material.TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    type={passwordDisplay}
                    onChange={(event) => this.setState({ password: event.target.value })}
                  />
                </Form.Group>
                <Form.Group css={css`margin-bottom: 0px;`}>
                  {!submitting && (
                    <Button
                      type="submit"
                      variant={loginDone ? 'secondary' : 'primary'}
                      disabled={loginDone}
                      onClick={this.handleLogin}
                      css={css`
                        height: 45px;
                        width: 100%;
                        background-color: #1A6B54 !important;
                        border-color: #1A6B54 !important;
                        outline: 0 !important;
                        box-shadow: none !important;
                        border-radius: 0 !important;

                        :hover {
                          background-color: #BA9E66 !important;
                          border-color: #BA9E66!important;
                          outline: 0 !important;
                          box-shadow: none !important;
                        }

                        :focus {
                          background-color: #1A6B54 !important;
                          border: 1px solid #1A6B54 !important;
                          outline: 0 !important;
                          box-shadow: none !important;
                        }

                        :active {
                          background-color: #1A6B54 !important;
                          border: 1px solid #1A6B54 !important;
                          outline: 0 !important;
                          box-shadow: none !important;
                        }
                      `}
                    >
                      Sign In
                    </Button>
                  )}
                  {submitting && <Spinner />}
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Material.Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  login.passwordReset(username, subscriberId);
                }}
                underline="always"
              >
                Forgot Password
              </Material.Link>
              <Material.Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.open('https://www.forcelink.net/support');
                }}
                underline="always"
                color="primary"
              >
                Log Support Issue
              </Material.Link>
            </Card.Footer>
          </div>
        </div>
      </div>
    );
  }
}
