/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import {
  Button, Form, Row, Col, Card, Container,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CircularProgress as Spinner } from '@material-ui/core';
import * as Material from '@material-ui/core';
import { AddressInput } from '../../components/joburg_water/address_input';
import {
  register,
  getProfile,
  updateProfile,
} from '../../scripts/joburg_water/profileScripts';
import { handleLogging, handleOTP } from '../../functions/forms';
import { Context } from '../../functions/joburg_water/context';
import { getProfileCustomerTypes } from '../../functions/apiCalls';
import { OTPModal } from '../../components/OTPModal';
import Ripple from '../../components/joburg_water/ripple';
import SlideIn from '../../components/joburg_water/slidein';

export class ProfileForm extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    const {
      newProfile,
    } = this.props;
    /**
     * requiredMissing: null = the input is not required (or not displayed yet)
     * false = the input is required and correctly filled in upon submission
     * true = the input is required and not filled in upon submission
     */
    this.state = {
      entries: this.initEntriesWithMap(),
      requiredMissing: this.initRequiredMissingWithMap(),
      customerTypesList: [],
      showModal: false,
      submitting: false,
    };
    this.newProfile = newProfile;
  }

  /**
   * INIT VALUES >>
   */
  initEntries = () => ({
    name: '',
    profileCustomerType: '',
    accountNumber: '',
    meterNumber: '',
    password: '',
    passwordHint: '',
    contactPerson: '',
    mobileNumber: '',
    homeNumber: '',
    emailAddress: '',
    optOutPreferences: [],
  });

  initEntriesWithMap = () => ({
    ...this.initEntries(),
    building: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
    description: '',
  });

  initRequiredMissing = () => ({
    accountNumber: false,
    name: false,
    profileCustomerType: false,
    password: false,
    passwordHint: false,
    contactPerson: false,
    mobileNumber: false,
  });

  initRequiredMissingWithMap = () => ({
    ...this.initRequiredMissing(),
    streetNumber: false,
    streetName: false,
    suburb: false,
  });
  /**
   * << INIT VALUES
   */

  componentDidMount = async () => {
    if (!this.newProfile) {
      const entries = await getProfile();
      Object.keys(entries).forEach((entry) => {
        if (entries[entry] == null) {
          entries[entry] = this.initEntries()[entry];
        }
      });
      this.setState({ entries });
    }
    const customerTypesList = (await getProfileCustomerTypes('joburg_water')) || [];
    this.setState({ customerTypesList });
  };

  handleSubmit = async () => {
    const { setAuth } = this.context;
    const { entries } = this.state;
    if (handleLogging(this.state, (s) => this.setState(s)) === true) {
      let valid = true;
      const regexPhone = /^0\d{9}$/;
      const isValidNumber = regexPhone.test(entries.mobileNumber);
      if (!isValidNumber && this.newProfile) {
        valid = false;
        alert('Invalid mobile number, please start with a 0 and provide a ten digit number.');
      } else if (entries.emailAddress !== undefined && entries.emailAddress !== null && entries.emailAddress !== '') {
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = regexEmail.test(entries.emailAddress);
        if (!isValidEmail) {
          alert('Invalid email address.');
          valid = false;
        }
      }
      if (valid) {
        console.log('handling submit');
        if (this.newProfile) {
          const registerResponse = await register(
            entries,
            (s) => this.setState(s),
            setAuth,
          );
          console.log('register response:', registerResponse);
          const { result } = registerResponse;
          if (result === 'OTP_REQUIRED' || result === 'OTP_ERROR') { this.setState({ showModal: true }); }
          if (result === 'ALREADY_REGISTERED' || result === 'SUCCESS') { this.setState({ showModal: false }); }
        } else {
          updateProfile(entries, (s) => this.setState(s));
        }
      }
    }
    this.setState({
      submitting: false,
    });
  };

  /**
   * UPDATE VALUES >>
   */
  setEntries = (name, val) => {
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate[name] = val;
    this.setState({ entries: toUpdate });
    return val;
  };

  updateEntries = (name, value) => this.setEntries(name, value);

  updateRequiredState = (name, bool) => {
    const { requiredMissing } = this.state;
    const toUpdate = { ...requiredMissing };
    toUpdate[name] = bool;
    this.setState({ requiredMissing: toUpdate });
  };

  initRequiredState = (name) => this.updateRequiredState(name, false);

  updateReqEntries = (name, value) => {
    const newVal = this.updateEntries(name, value);
    if (newVal !== '') {
      this.initRequiredState(name);
    }
  };
  /**
   * << UPDATE VALUES
   */

  onResultFound = (result) => {
    const {
      streetNumber, streetName, city, postalCode, suburb, latitude, longitude, orgUnitCode,
    } = result;
    const { entries, requiredMissing } = this.state;
    const toUpdate = { ...entries };
    toUpdate.streetNumber = streetNumber || '';
    toUpdate.streetName = streetName || '';
    toUpdate.city = city || '';
    toUpdate.postalCode = postalCode || '';
    toUpdate.suburb = suburb || '';
    toUpdate.latitude = latitude || '';
    toUpdate.longitude = longitude || '';
    toUpdate.orgUnitCode = orgUnitCode || '';
    const toUpdateReq = { ...requiredMissing };
    ['streetNumber', 'streetName', 'suburb'].forEach((req) => {
      console.log(req, toUpdate[req], toUpdate[req] == null || toUpdate[req] === '');
      if (!(toUpdate[req] == null || toUpdate[req] === '')) {
        toUpdateReq[req] = false;
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      entries: toUpdate,
      requiredMissing: toUpdateReq,
    }));
  };

  validStyle = (val) => {
    if (val) { return { color: 'red' }; } return null;
  };

  render() {
    const { newProfile } = this;
    const { setAuth } = this.context;
    const {
      entries,
      entries: {
        name,
        profileCustomerType,
        accountNumber,
        meterNumber,
        password,
        passwordHint,
        contactPerson,
        mobileNumber,
        homeNumber,
        emailAddress,
        optOutPreferences,
      },
      requiredMissing,
      customerTypesList,
      showModal,
      submitting,
    } = this.state;

    return (
      <Container fluid id="ProfileForm">
        <div className="slide-container">
          <SlideIn settings={{
            marginRight: 0,
            width: '100%',
            from: { marginRight: -700 },
            config: { mass: 1, tension: 300, friction: 30 },
          }}
          >
            <Card id="formCard" className="text-center">
              <Card.Header>
                <img src="joburg_water/logo.jpg" className="login-logo" alt="Joburg Water Logo" />
                <div className="login-title">
                  {newProfile
                    ? 'Account Registration'
                    : 'Edit your Profile'}
                </div>
              </Card.Header>
              <Card.Body id="formBody">
                <Form className="form">
                  <div className="section">
                    <Form.Group className="formLabels">
                      <Form.Label className="largeLabel">
                        Customer Account Details
                      </Form.Label>
                    </Form.Group>
                    <Form.Group>
                      <Row className="formInputs">
                        {/* NAME */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={this.validStyle(requiredMissing.name === true)}
                            >
                              Name
                              {' '}
                              <span css={css`color: red`}>*</span>
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            name="name"
                            type="text"
                            required
                            isInvalid={requiredMissing.name === true}
                            value={name}
                            onChange={
                            (e) => {
                              this.updateReqEntries(e.target.name, e.target.value);
                            }
                          }
                          />
                        </Col>
                        {/* CUSTOMER TYPE */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={this.validStyle(requiredMissing.profileCustomerType === true)}
                            >
                              Customer Type
                              {' '}
                              <span css={css`color: red`}>*</span>
                            </Form.Label>
                          </Form.Group>
                          <Material.TextField
                            name="profileCustomerType"
                            select
                            fullWidth
                            margin="normal"
                            label=""
                            variant="outlined"
                            required
                            error={requiredMissing.profileCustomerType === true}
                            value={profileCustomerType}
                            onChange={
                            (e) => {
                              this.updateReqEntries(e.target.name, e.target.value);
                            }
                          }
                          >
                            <Material.MenuItem
                              key="blank"
                              value=""
                            />
                            {customerTypesList.map((type) => (
                              <Material.MenuItem
                                key={type.value}
                                value={type.value}
                              >
                                {type.label}
                              </Material.MenuItem>
                            ))}
                          </Material.TextField>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="formInputs">
                        {/* ACCOUNT NUMBER */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={this.validStyle(requiredMissing.accountNumber === true)}
                            >
                              Account Number
                              {' '}
                              <span css={css`color: red`}>*</span>
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            name="accountNumber"
                            type="text"
                            isInvalid={requiredMissing.accountNumber === true}
                            value={accountNumber}
                            onChange={(e) => {
                              this.updateReqEntries(e.target.name, e.target.value);
                            }}
                          />
                        </Col>
                        {/* METER NUMBER */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label className="smallLabel">
                              Meter Number
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            type="text"
                            name="meterNumber"
                            value={meterNumber}
                            onChange={(e) => {
                              this.updateEntries(e.target.name, e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="formInputs">
                        {/* PASSWORD */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={this.validStyle(requiredMissing.password === true)}
                            >
                              Password
                              {' '}
                              <span css={css`color: red`}>*</span>
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            type="password"
                            name="password"
                            required
                            isInvalid={requiredMissing.password === true}
                            value={password}
                            onChange={
                            (e) => {
                              this.updateReqEntries(e.target.name, e.target.value);
                            }
                          }
                          />
                        </Col>
                        {/* PASSWORD HINT */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={this.validStyle(requiredMissing.passwordHint === true)}
                            >
                              Password Hint
                              {' '}
                              <span css={css`color: red`}>*</span>
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            type="text"
                            name="passwordHint"
                            required
                            isInvalid={requiredMissing.passwordHint === true}
                            value={passwordHint}
                            onChange={
                            (e) => {
                              this.updateReqEntries(e.target.name, e.target.value);
                            }
                          }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="section">
                    <Form.Group className="formLabels">
                      <Form.Label className="largeLabel">
                        Contact Details
                      </Form.Label>
                    </Form.Group>
                    <Form.Group>
                      <Row className="formInputs">
                        {/* CONTACT PERSON */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={this.validStyle(requiredMissing.contactPerson === true)}
                            >
                              Contact Person
                              {' '}
                              <span css={css`color: red`}>*</span>
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            type="text"
                            name="contactPerson"
                            required
                            isInvalid={requiredMissing.contactPerson === true}
                            value={contactPerson}
                            onChange={
                            (e) => {
                              this.updateReqEntries(e.target.name, e.target.value);
                            }
                          }
                          />
                        </Col>
                        {/* MOBILE NUMBER */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={this.validStyle(requiredMissing.mobileNumber === true)}
                            >
                              Mobile Number
                              {' '}
                              <span css={css`color: red`}>*</span>
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            type="tel"
                            name="mobileNumber"
                            aria-describedby="helperText"
                            required
                            isInvalid={requiredMissing.mobileNumber === true}
                            value={mobileNumber}
                            onChange={
                            (e) => {
                              this.updateReqEntries(e.target.name, e.target.value);
                            }
                          }
                            readOnly={!newProfile}
                          />
                          <Form.Text muted id="helperText">
                            This will be your username
                          </Form.Text>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="formInputs">
                        {/* HOME NUMBER */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label className="smallLabel">
                              Home Number
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            type="tel"
                            name="homeNumber"
                            value={homeNumber}
                            onChange={(e) => {
                              this.updateEntries(e.target.name, e.target.value);
                            }}
                          />
                        </Col>
                        {/* EMAIL ADDRESS */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label className="smallLabel">
                              Email Address
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            type="email"
                            name="emailAddress"
                            value={emailAddress}
                            onChange={(e) => {
                              this.updateEntries(e.target.name, e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="section">
                    {/* SMS OPT-OUT PREFERENCES */}
                    <Form.Group className="formLabels">
                      <Form.Label className="largeLabel">
                        SMS opt-out Preferences (tick to opt out)
                      </Form.Label>
                    </Form.Group>
                    <Form.Group>
                      {[
                        ['Outage Notifications', 'ON'],
                        ['Other Notifications', 'OTN'],
                        ['Company Announcements', 'CA'],
                        ['Community Empowerment', 'CE'],
                      ].map((value) => (
                        <Form.Row key={value[1]}>
                          <Form.Check
                            type="checkbox"
                            id={`optOut-${value[1]}`}
                            label={value[0]}
                            checked={(optOutPreferences || []).includes(value[1])}
                            onChange={(e) => {
                              const code = value[1];
                              console.log(
                                'code:',
                                code,
                                'checked',
                                e.target.checked,
                              );
                              if (e.target.checked) {
                                this.setEntries(
                                  'optOutPreferences',
                                  optOutPreferences.concat(code),
                                );
                              } else {
                                this.setEntries(
                                  'optOutPreferences',
                                  optOutPreferences.filter((p) => p !== code),
                                );
                              }
                            }}
                          />
                        </Form.Row>
                      ))}
                    </Form.Group>
                  </div>
                  <AddressInput
                    entries={entries}
                    updateEntries={(nameF, value) => {
                      this.updateEntries(nameF, value);
                    }}
                    requiredMissing={requiredMissing}
                    updateReqEntries={(nameF, value) => {
                      this.updateReqEntries(nameF, value);
                    }}
                    onResultFound={this.onResultFound}
                    page="profile"
                    subscriberId="joburg_water"
                  />
                </Form>
              </Card.Body>
              <Card.Footer>
                <div className="button-group">
                  <Form.Group id="finalButtons">
                    <Link to="/home">
                      <Button className="secondary" type="submit">
                        Back
                        <Ripple />
                      </Button>
                    </Link>
                    <div id="submit-spinner">
                      {!submitting && (
                      <Button
                        className="primary"
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        {newProfile && 'Register'}
                        {!newProfile && 'Save and continue'}
                        <Ripple />
                      </Button>
                      )}
                      {submitting && <Spinner />}
                    </div>
                  </Form.Group>
                </div>
              </Card.Footer>
            </Card>
          </SlideIn>
        </div>
        <OTPModal
          onChangeOTP={(OTP) => this.setEntries('otp', OTP)}
          showModal={showModal}
          setShowModal={(s) => this.setState({ showModal: s })}
          setSubmitting={(submit) => this.setState({ submit })}
          mobileNumber={mobileNumber}
          handleOTP={(setOtp, otp) => handleOTP(
            register,
            setAuth,
            entries,
            (s) => this.setState(s),
            setOtp,
            otp,
          )}
        />
      </Container>
    );
  }
}

export const RegistrationForm = () => <ProfileForm newProfile />;
