import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DescriptionOutlined, AccountCircleOutlined } from '@material-ui/icons';
import { Context } from '../../functions/fidelity_sf/context';
import Header from './header';
import SupportModal from './support_modal';

const FidelityFSHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const logoSrc = 'fidelity_sf/logo_small.png';
  const { labels } = useContext(Context);
  const version = (labels && labels['js.versionNo']) || '';

  const dropdowns = [
    [
      'tickets',
      DescriptionOutlined,
      'Tickets',
      [
        ['/tickets', 'View Tickets'],
        ['/ticket', 'Create Tickets'],
      ],
    ],
    [
      'customer',
      AccountCircleOutlined,
      'Customer',
      [
        ['/details', 'My Details'],
        [() => setShowModal(true), 'About Forcelink'],
      ],
    ],
  ];

  return (
    <>
      <Header {...{
        history, logoSrc, dropdowns,
      }}
      />
      <SupportModal
        showModal={showModal}
        setShowModal={setShowModal}
        version={version}
      />
    </>
  );
};

export default FidelityFSHeader;
