import { apiUrl } from '../../constants/constantsGlobal';
import { logFaultMapping } from '../../constants/joburg_water/constants';
import {
  fetchApi, findLabelValuesByClassAndPropertyForCustomPortal,
  findLabelValuesByClassAndProperty,
} from '../../functions/apiCalls';
import { getData } from '../../functions/forms';

export const logFault = (entries, setState, address) => {
  const data = getData(entries, logFaultMapping);

  console.log('entries:', entries);
  console.log('data:', data);
  console.log('data stringified:', JSON.stringify(data));

  if (entries.subSubCallType !== '') {
    data.childWorkType = entries.subSubCallType;
  }

  console.log('data:', data);

  return fetchApi(`${apiUrl}calltakemanager/logCall${address}`, 'POST', {
    body: JSON.stringify(data),
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((json) => {
      const { result, errorMessage } = json;
      console.log('logfault:', json, result === 'FAILURE');
      if (result === 'FAILURE') {
        setState({
          submitting: false,
        });
        alert(`${errorMessage}`);
      } else {
        const entriesBlank = { ...entries };
        for (let i = 0; i < entriesBlank.length; i++) {
          entriesBlank[i] = '';
        }
        setState({
          submitting: false,
          entries: entriesBlank,
        });
      }
      return json;
    })
    .catch((e) => {
      console.error(e);
      setState({
        submitting: false,
      });
      const errMsg = e.responseText != null && e.responseText !== ''
        ? e.responseText
        : 'unknown error, you are possibly not connected.';
      alert(`Error with logging your fault: ${errMsg}`);
      return undefined;
    });
};

export const getCallTypes = async () => findLabelValuesByClassAndPropertyForCustomPortal('WorkOrderRequest', 'workRequestType', '', '')
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const getCallTypesByCode = async (currentEntryCode, useCodeForValue) => findLabelValuesByClassAndProperty('WorkOrderRequest', 'workRequestType', currentEntryCode, false, useCodeForValue)
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const getCallByCode = async (code) => fetchApi(
  `${apiUrl}calltakemanager/getCallByCode?code=${code}`,
  'GET',
  { credentials: 'include' },
)
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const getSubCallTypes = async (callTypeCode) => findLabelValuesByClassAndPropertyForCustomPortal('WorkOrderRequest', 'workRequestType', callTypeCode, '')
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const getEscalationOptionsWithCode = async () => findLabelValuesByClassAndProperty('WorkOrderRequest', 'customLookup5', '', false, true)
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const getEscalationOptions = async () => findLabelValuesByClassAndProperty('WorkOrderRequest', 'customLookup5', '', false, false)
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const getEscalationReasonsWithCode = async () => findLabelValuesByClassAndProperty('WorkOrderRequest', 'customLookup6', '', false, true)
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const getEscalationReasons = async () => findLabelValuesByClassAndProperty('WorkOrderRequest', 'customLookup6', '', false, false)
  .then((res) => {
    console.log(res);
    return res.json();
  })
  .catch((e) => console.error(e));

export const logFaultMyAddress = async (entries, setState) => {
  const resp = await logFault(entries, setState, 'MyAddress');
  return resp;
};

export const logFaultOtherAddress = async (entries, setState) => {
  const resp = await logFault(entries, setState, 'OtherAddress');
  return resp;
};
