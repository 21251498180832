import React, { useContext, useState } from 'react';
import {
  MenuItem, FormControl, InputLabel, Select,
} from '@mui/material';
import { Table } from '../../components/Table';
import { Context } from '../../functions/syntell/context';

export const ViewTickets = () => {
  const { username } = useContext(Context);
  document.title = 'Work requests | MTM Customer Portal';

  const [filterType, setFilterType] = useState('all');
  const [additionalFilters, setAdditionalFilters] = useState([]);

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setFilterType(selectedFilter);

    const newAdditionalFilters = [];

    // Add relevant additional filters based on the selected filter type
    if (selectedFilter === 'open') {
      newAdditionalFilters.push({
        property: 'workRequestStatus.open',
        value: 'true',
        options: [],
      });
    } else if (selectedFilter === 'closed') {
      newAdditionalFilters.push({
        property: 'workRequestStatus.open',
        value: 'false',
        options: [],
      });
    }

    setAdditionalFilters(newAdditionalFilters);
  };

  const filters = [{
    property: 'customer.user.username',
    value: username,
    options: [],
  }];

  return (
    <div>
      <div style={{
        marginTop: '2px',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        width: '10%',
        zIndex: 10,
      }}
      >
        <FormControl style={{ minWidth: 100 }}>
          <InputLabel id="filter-dropdown-label">Filter Status</InputLabel>
          <Select
            labelId="filter-dropdown-label"
            value={filterType}
            label="Filter Work Request Status"
            onChange={handleFilterChange}
            fullWidth
            style={{ minWidth: 90, height: 40 }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="open">Open</MenuItem>
            <MenuItem value="closed">Closed</MenuItem>
          </Select>
        </FormControl>
      </div>

      <Table
        Context={Context}
        title="Work Requests"
        tableModelClass="WorkOrderRequest"
        id="workorderrequest"
        columnView="Quote View"
        filters={filters}
        additionalFilters={additionalFilters}
        showExport
        sorts={{
          property: 'dateCreated',
          order: 'DESC',
        }}
      />
    </div>
  );
};
