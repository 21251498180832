import { fetchApi } from '../functions/apiCalls';
import { apiUrl } from '../constants/constantsGlobal';

const tomtomSearch = async (query, params) => fetchApi(
  `${apiUrl}addressmanager/tomtomSearch?query=${encodeURIComponent(
    query,
  )}&params=${encodeURIComponent(
    params,
  )}`,
  'GET',
  { credentials: 'include' },
)
  .then((response) => response.json())
  .then((resp) => resp)
  .catch((e) => {
    console.error(e);
    return undefined;
  });

const tomtomReverseGeocode = async (lat, lng, params) => fetchApi(
  `${apiUrl}addressmanager/tomtomReverseGeocode?lat=${lat}&lng=${lng}&params=${encodeURIComponent(
    params,
  )}`,
  'GET',
  { credentials: 'include' },
)
  .then((response) => response.json())
  .then((resp) => resp)
  .catch((e) => {
    console.error(e);
    return undefined;
  });

const tomtomSearchApi = (query, lat, lng, radius) => tomtomSearch(query, `&radius=${radius * 1000}${lat && lng ? `&lat=${lat}&lon=${lng}` : ''}`);

const tomtomSuburbSearchApi = (query, lat, lng, radius) => tomtomSearch(query, `&radius=${radius * 1000}${lat && lng ? `&lat=${lat}&lon=${lng}` : ''}&entityTypeSet=MunicipalitySubdivision`);

export const getSearchedPlaces = async (...args) => {
  const json = await tomtomSearchApi(...args);
  return json;
};

export const getSuburbs = async (...args) => {
  const json = await tomtomSuburbSearchApi(...args);
  return json;
};

export const getReverseGeocodedPlace = async (lat, lng) => {
  const json = await tomtomReverseGeocode(lat, lng, '&returnSpeedLimit=false&returnRoadUse=false&allowFreeformNewLine=false&returnMatchType=false&view=Unified');
  return json;
};

export const googleAddressSearch = async (query, country) => fetchApi(
  `${apiUrl}addressmanager/googleAddressSearch?query=${encodeURIComponent(
    query,
  )}&country=${encodeURIComponent(
    country,
  )}`,
  'GET',
  { credentials: 'include' },
)
  .then((response) => response.json())
  .then((resp) => resp)
  .catch((e) => {
    console.error(e);
    return null;
  });

const mapAddress = (data) => {
  const addressComponents = data.address_components.reduce(
    (address, component) => {
      const type = component.types[0];
      const longName = component.long_name;
      switch (type) {
        case 'street_number':
          address.streetNumber = longName;
          break;
        case 'route':
          address.streetName = longName;
          break;
        case 'sublocality':
          address.suburb = longName;
          break;
        case 'sublocality_level_1':
          address.suburb = longName;
          break;
        case 'sublocality_level_2':
          address.suburb = longName;
          break;
        case 'locality':
          address.city = longName;
          break;
        case 'postal_code':
          address.postalCode = longName;
          break;
        default:
          break;
      }
      return address;
    },
    {},
  );
  return {
    ...addressComponents,
    freeformAddress: data.formatted_address,
  };
};

export const getPlaceDetailsAsJson = async (placeId) => fetchApi(
  `${apiUrl}addressmanager/getPlaceDetailsAsJson?placeId=${encodeURIComponent(
    placeId,
  )}`,
  'GET',
  { credentials: 'include' },
)
  .then((response) => response.json())
  .then((resp) => ({
    address: mapAddress(resp),
    position: {
      lat: resp.latitude,
      lon: resp.longitude,
    },
  }))
  .catch((e) => {
    console.error(e);
    return null;
  });

export const googleReverseGeocode = async (lat, lon) => fetchApi(
  `${apiUrl}addressmanager/googleReverseGeocode?lat=${lat}&lon=${lon}`,
  'GET',
  { credentials: 'include' },
)
  .then((response) => response.json())
  .then((resp) => ({
    address: mapAddress(resp),
    position: {
      lat: resp.latitude,
      lon: resp.longitude,
    },
  }))
  .catch((e) => {
    console.error(e);
    return null;
  });
