/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import '../../styles/fidelity_sf/home.scss';
import {
  ViewListOutlined,
  NoteAddOutlined,
  AccountCircleOutlined,
} from '@mui/icons-material';
import KPI from '../../components/fidelity_sf/kpi';

const Home = () => (
  <div css={css`
    border-radius: 8px;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(2px);
    padding: 16px;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 18px;
  `}
  >
    <div
      css={css`
        height: fit-content;
        background-color: #ffffff;
        padding: 20px 24px;
        box-shadow: 0px 0px 4px 0px rgba(85, 107, 130, 0.32);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <div css={css`
          color: #262626;
          padding: 4px 16px;
          border-radius: 4px;
          border: 1px solid #D9D9D9;
          width: fit-content;
        `}
        >
          Navigate
        </div>
        <div
          css={css`
          height: 1px;
          width: 100%;
          background: rgba(0, 0, 0, 0.06);
        `}
        />
      </div>
      <div
        className="iconRow"
        css={css`
        gap: 24px;
      `}
      >
        {[
          [
            '/tickets',
            'View Tickets',
            ViewListOutlined,
          ],
          [
            '/ticket',
            'Create Tickets',
            NoteAddOutlined,
          ],
          [
            '/details',
            'My Details',
            AccountCircleOutlined,
          ],
        ].map(([path, title, Icon]) => (
          <Link to={path} key={path}>
            <Card link href="">
              <Card.Content>
                <Icon />
                <Card.Header>{title}</Card.Header>
              </Card.Content>
            </Card>
          </Link>
        ))}
      </div>
    </div>
    <div
      css={css`
        height: fit-content;
        background-color: #ffffff;
        padding: 20px 24px;
        box-shadow: 0px 0px 4px 0px rgba(85, 107, 130, 0.32);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .kpi-card {
          height: 115px;
          width: 254px;
          padding: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;

          .kpi-value {
            color: #262626;
            font-size: 20px;
            font-weight: 600;
          }

          .kpi-title {
            color: #595959;
            font-size: 14px;
            font-weight: 500;
          }
        } 
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <div css={css`
          color: #262626;
          padding: 4px 16px;
          border-radius: 4px;
          border: 1px solid #D9D9D9;
          width: fit-content;
        `}
        >
          Service Request/Client Tickets
        </div>
        <div
          css={css`
          height: 1px;
          width: 100%;
          background: rgba(0, 0, 0, 0.06);
        `}
        />
      </div>
      <div className="iconRow">
        {[
          [
            'Total number of tickets logged',
            'rgb(65, 117, 5)',
            'select count(wor.id) from work_order_request wor join customer cus on cus.id = wor.customer_id join app_user au on au.customer_id = cus.id where au.id = ${USER_ID} and wor.active;',
          ],
          [
            'Number of tickets created',
            'rgb(126, 211, 33)',
            'select count(wor.id) from work_order_request wor join customer cus on cus.id = wor.customer_id join app_user au on au.customer_id = cus.id where au.id = ${USER_ID} and wor.work_request_status_id = 2151 and wor.active;',
          ],
          [
            'Number of tickets resolved',
            'rgb(126, 211, 33)',
            'select count(wor.id) from work_order_request wor join customer cus on cus.id = wor.customer_id join app_user au on au.customer_id = cus.id where au.id = ${USER_ID} and wor.work_request_status_id = 2161 and wor.active;',
          ],
        ].map(([title, color, query]) => (
          <KPI
            key={title}
            title={title}
            color={color}
            query={query}
          />
        ))}
      </div>
      <div className="iconRow">
        {[
          [
            'Number of tickets with a linked work order',
            'rgb(248, 231, 28)',
            'select count(wor.id) from work_order_request wor join customer cus on cus.id = wor.customer_id join app_user au on au.customer_id = cus.id join work_order wo on wo.id = wor.work_order_id where au.id = ${USER_ID} and wor.active;',
          ],
          [
            'Number of tickets where the work order is assigned to the technician',
            'rgb(248, 231, 28)',
            'select count(wor.id) from work_order_request wor join customer cus on cus.id = wor.customer_id join app_user au on au.customer_id = cus.id join work_order wo on wo.id = wor.work_order_id where au.id = ${USER_ID} and wo.workorder_status_id = 1077 and wor.active;',
          ],
          [
            'Number of tickets cancelled',
            'rgb(245, 166, 35)',
            'select count(wor.id) from work_order_request wor join customer cus on cus.id = wor.customer_id join app_user au on au.customer_id = cus.id join work_order wo on wo.id = wor.work_order_id where au.id = ${USER_ID} and wor.work_request_status_id = 2164 and wor.active;',
          ],
        ].map(([title, color, query]) => (
          <KPI
            key={title}
            title={title}
            color={color}
            query={query}
          />
        ))}
      </div>
    </div>
  </div>
);

export default Home;
