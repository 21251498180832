/**
 * @author Matthew Castle
 *
 * Contains a background effect
 */

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { memo, useState, useEffect } from 'react';

const Background = memo(() => {
  const [randomValues, setRandomValues] = useState([]);

  const generateRandomValues = () => {
    const values = Array.from({ length: 10 }).map(() => ({
      circleSize: `calc(10vw + ${Math.random() * 10}vw)`,
      bounceWidth: `${Math.random() * 100}vw`,
      bounceHeight: `${Math.random() * 100}vh`,
      top: `${Math.random() * 100}vh`,
      left: `${Math.random() * 100}vw`,
    }));
    setRandomValues(values);
  };

  useEffect(() => {
    generateRandomValues();
  }, []);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        background-color: #20463A;
        transition: background-color 2s;
      `}
    >
      <div
        css={css`
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-color:#20463A;
          transition: background-color 2s;
          position: relative;
          filter: blur(100px);
        `}
      >
        {randomValues.map(
          (
            values,
            index,
          ) => (
            <div
              key={index}
              className="circle"
              css={css`
                --circle-size: ${values.circleSize};
                --bounce-width: ${values.bounceWidth};
                --bounce-height: ${values.bounceHeight};

                width: var(--circle-size);
                background: #306856;
                transition: background 2s;
                top: ${values.top};
                left: ${values.left};
                aspect-ratio: 1;
                height: auto;
                border-radius: 50%;
                position: absolute;
                animation: bounce 30s ease-in-out infinite;

                @keyframes bounce {
                  0%,
                  100% {
                    transform: translateY(initial-x) translateX(initial-y);
                  }
                  25% {
                    transform: translateY(calc(-1 * var(--bounce-height)))
                      translateX(calc(var(--bounce-width)));
                  }
                  50% {
                    transform: translateY(0)
                      translateX(calc(-1 * var(--bounce-width)));
                  }
                  75% {
                    transform: translateY(calc(-1 * var(--bounce-height)))
                      translateX(calc(var(--bounce-width)));
                  }
                }
              `}
            />
          ),
        )}
      </div>
    </div>
  );
});

export default Background;
