import React from 'react';
import './pages/joburg_water/styles.scss';
import {
  Switch, Route, Redirect,
} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Context } from './functions/joburg_water/context';
import { PortalNav } from './components/joburg_water/header';
import { Login } from './pages/joburg_water/login';
import { HomePage } from './pages/joburg_water/home_page';
import {
  LogFaultMyAddress,
  LogFaultOtherAddress,
} from './pages/joburg_water/log_fault';
import { ProfileForm, RegistrationForm } from './pages/joburg_water/profile';
import OpenFaults from './pages/joburg_water/open_faults';
import AllFaults from './pages/joburg_water/all_faults';
import Fault from './pages/joburg_water/fault';
import { Notifications } from './pages/joburg_water/notifications';
import { JoburgWaterAppContainer, Footer } from './pages/joburg_water/joburg_water_page.styled';

export class JoburgWaterApp extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { openSnackbar: true };
  }

  snackbarClose = (e, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ openSnackbar: false });
  };

  render() {
    document.title = 'Joburg Water Portal';
    const { openSnackbar } = this.state;
    const {
      authenticated,
      logout,
      cookies: { cookies },
      hasApiKey,
    } = this.context;
    console.log('Authenticated:', authenticated);

    if (authenticated === null) {
      return (
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      );
    }

    const portalNavPaths = [
      '/profile',
      '/notifications',
      '/logFaultMyAddress',
      '/logFaultOtherAddress',
      '/openFaults',
      '/allFaults',
      '/fault',
    ];

    const pagesPaths = [
      ['/profile', <ProfileForm />],
      ['/logFaultMyAddress', <LogFaultMyAddress />],
      ['/logFaultOtherAddress', <LogFaultOtherAddress />],
      ['/openFaults', <OpenFaults />],
      ['/allFaults', <AllFaults />],
      ['/notifications', <Notifications />],
      ['/fault/:code', <Fault />],
    ];

    return (
      <JoburgWaterAppContainer
        id="JoburgWaterApp"
      >
        {!hasApiKey && (
        <Switch>
          {portalNavPaths.map((path, i) => (
            <Route path={path} key={i}>
              <PortalNav />
            </Route>
          ))}
        </Switch>
        )}
        <div id="App">
          <Container fluid id="AppContainer">
            <Switch>
              <Route
                path="/login"
                render={({ location }) => (!authenticated ? (
                  <Login />
                ) : (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                ))}
              />
              <Route
                path="/register"
                render={({ location }) => (!authenticated ? (
                  <RegistrationForm />
                ) : (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                ))}
              />
              {!authenticated && (
                <Route
                  path="/"
                  render={({ location }) => (
                    <Redirect
                      to={{ pathname: '/login', state: { from: location } }}
                    />
                  )}
                />
              )}
              <Route
                exact
                path="/"
                render={({ location }) => (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                )}
              />
              <Route
                path="/home"
                render={({ location }) => (authenticated ? (
                  <HomePage />
                ) : (
                  <Redirect
                    to={{ pathname: '/login', state: { from: location } }}
                  />
                ))}
              />
              <Route
                path="/logout"
                render={() => {
                  logout();
                }}
              />
              {pagesPaths.map(([path, page], i) => (
                <Route path={path} key={i}>
                  {page}
                </Route>
              ))}
              <Route
                exact
                path="*"
                render={({ location }) => (
                  <Redirect
                    to={{ pathname: '/home', state: { from: location } }}
                  />
                )}
              />
            </Switch>
          </Container>
        </div>
        <Route path="/">
          <Footer>
            <div
              style={{ paddingRight: '5px' }}
              className="link"
              role="button"
              tabIndex={0}
              onClick={() => {
                window.open('https://www.forcelink.net');
              }}
            >
              Powered by Forcelink&trade;
              {' '}
              {new Date().getFullYear()}
              {' '}
              |
            </div>
            <div
              className="link"
              role="button"
              tabIndex={0}
              onClick={() => {
                window.open('https://www.forcelink.net/privacy-policy');
              }}
            >
              Privacy Policy
            </div>
          </Footer>
        </Route>
        {cookies.SESSION != null
          && cookies.username != null
          && cookies.username !== '' && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={this.snackbarClose}
            >
              <Alert
                onClose={this.snackbarClose}
                severity="info"
                elevation={6}
                variant="filled"
              >
                Logged in with username
                {' '}
                <b>{cookies.username}</b>
              </Alert>
            </Snackbar>
        )}
      </JoburgWaterAppContainer>
    );
  }
}

// const NoMatch = () => {
//   document.title = '404 Not Found | Joburg Water';
//   const location = useLocation();
//   return (
//     <div>
//       <h3>
//         404: There doesn&apos;t seem to be a page titled
//         {' '}
//         <code>{location.pathname}</code>
//       </h3>
//     </div>
//   );
// };
