import React, {
  useState, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  Box,
  LinearProgress,
  SvgIcon,
} from '@mui/material';
import * as Material from '@material-ui/core';
import {
  LoginContainer,
  InnerContainer,
  SubscriberImage,
  PortalName,
  OuterContainer,
  StyleContainer,
  PasswordVisibility,
  PasswordContainer,
  LoginButton,
  RegisterButton,
  StyleHr,
  Footer,
} from '../../pages/servest_is/login/login.styled';
import * as login from '../../scripts/loginScripts';
import SlideIn from '../_shared/slidein';
import Ripple from '../_shared/ripple';
import AlertBar from '../_shared/alertBar';

const LoginForm = (props) => {
  const { setAuth, subscriberId } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [validSubmit, setValidSubmit] = useState(true);
  const [loading, setLoading] = useState(false);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordInputRef = useRef(null);

  const handleLogin = () => {
    setLoading(true);
    if (username === '' && password === '') {
      setOpenSnackbar(true);
      setValidSubmit(false);
      setToastMessage('Username and Password required');
      usernameRef.current.classList.add('invalid-input');
      passwordRef.current.classList.add('invalid-input');
      setLoading(false);
    } else if (username === '') {
      setOpenSnackbar(true);
      setValidSubmit(false);
      setToastMessage('Username required');
      usernameRef.current.classList.add('invalid-input');
      setLoading(false);
    } else if (password === '') {
      setOpenSnackbar(true);
      setValidSubmit(false);
      setToastMessage('Password required');
      passwordRef.current.classList.add('invalid-input');
      setLoading(false);
    } else {
      const isLoggedin = async () => {
        const success = await login.doLoginNoAlerts(username, password, setAuth, subscriberId);
        setLoading(false);
        if (!success) {
          setOpenSnackbar(true);
          setValidSubmit(false);
          setToastMessage('Could not log in');
        }
      };
      isLoggedin();
    }
  };

  return (
    <LoginContainer>
      <AlertBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        toastMessage={toastMessage}
        validSubmit={validSubmit}
      />
      <OuterContainer>
        <div />
        <InnerContainer
          style={{
            opacity: loading ? 0.7 : 1, pointerEvents: loading && 'none', border: '1px solid #dbdbdb', borderRadius: '2px',
          }}
        >
          <StyleContainer>
            <Box sx={{ width: 'calc(100% + 48px)', marginBottom: '40px' }}>
              {loading && <LinearProgress sx={{ color: '#102b7c' }} />}
            </Box>
            <SlideIn settings={{
              marginRight: 0,
              width: '100%',
              from: { marginRight: -450 },
              config: { mass: 1, tension: 300, friction: 30 },
            }}
            >
              <SubscriberImage />
              <div style={{
                width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '24px 0px',
              }}
              >
                <div style={{
                  width: '100%', height: '0px%', margin: '0px 8px', borderBottom: '1px solid #D9D9D9',
                }}
                />
                <SvgIcon>
                  <svg
                    width="58"
                    height="60"
                    viewBox="0 0 58 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M48.8059 31.5748C48.9305 30.1721 48.8381 28.7585 48.5319 27.3841C47.9703 25.2369 46.971 23.2298 45.597 21.4892C45.323 21.1307 45.2812 20.9048 45.597 20.5579C46.1868 19.9084 46.7371 19.2192 47.2688 18.5185C47.9204 17.6869 48.2957 16.6714 48.3419 15.6149C48.3881 14.5583 48.103 13.5138 47.5265 12.6282C46.8101 11.1808 45.6663 9.98994 44.2508 9.21759C42.8352 8.44525 41.2168 8.12896 39.6156 8.31178C38.832 8.4025 38.0771 8.66084 37.4017 9.06932C36.7263 9.4778 36.1462 10.0269 35.7007 10.6795C31.6396 15.7479 27.5738 20.814 23.4198 25.8126C22.6098 26.8445 22.1765 28.1234 22.1922 29.4365C22.2078 30.7496 22.6714 32.0177 23.5057 33.0299C24.3741 34.2376 25.5724 35.1675 26.9562 35.7073C27.3649 35.8657 27.3858 35.9402 27.1094 36.2894C25.6094 38.11 24.1327 39.9469 22.6582 41.7862C22.4725 42.019 22.3424 42.0982 22.0406 41.9142C17.9901 39.6009 14.9933 35.8015 13.6815 31.3164C13.0508 29.3284 12.9463 27.2101 13.3782 25.1694C13.8101 23.1286 14.7635 21.2355 16.1451 19.6756C20.3246 14.6607 24.3951 9.54105 28.5235 4.47496C30.4772 2.0808 33.2685 0.52447 36.3277 0.123607C40.3265 -0.331857 44.3673 0.478245 47.884 2.44044C51.4007 4.40264 54.2172 7.41864 55.939 11.066C56.8089 12.9298 57.2758 14.957 57.309 17.0145C57.3645 19.7124 56.4533 22.3409 54.7409 24.4227C52.8307 26.7509 50.9298 29.0946 49.0381 31.4538C48.9982 31.522 48.9685 31.5957 48.9499 31.6726L48.8083 31.5772"
                      fill="#D9D9D9"
                    />
                    <path
                      d="M9.1118 28.393C8.9004 32.0653 10.0862 35.6813 12.4299 38.5112C12.4951 38.5681 12.547 38.6386 12.5821 38.7178C12.6171 38.797 12.6343 38.8829 12.6325 38.9696C12.6308 39.0562 12.61 39.1413 12.5718 39.219C12.5336 39.2967 12.4788 39.3651 12.4113 39.4192C11.9469 39.936 11.529 40.5064 11.0646 41.028C10.2257 41.9548 9.73713 43.1469 9.68369 44.3973C9.63024 45.6477 10.0153 46.8774 10.772 47.8728C11.5225 49.1248 12.6039 50.1441 13.8964 50.818C15.189 51.4919 16.6423 51.794 18.0955 51.691C18.8744 51.6543 19.6355 51.4453 20.3244 51.079C21.0132 50.7127 21.6128 50.1982 22.08 49.5723C26.2596 44.3929 30.453 39.2236 34.6605 34.0644C35.4223 33.1284 35.8515 31.9646 35.8806 30.7568C35.9096 29.549 35.5367 28.3659 34.8207 27.3942C33.9499 25.9907 32.6536 24.9039 31.1218 24.2931C30.7549 24.1487 30.6016 24.0603 30.9221 23.6738C32.4151 21.8741 33.8872 20.0558 35.3524 18.2329C35.5591 17.9744 35.6868 17.8836 36.0165 18.0908C38.1389 19.3138 39.9892 20.9596 41.4531 22.9268C42.9171 24.894 43.9638 27.1409 44.5288 29.5291C44.9911 31.3159 45.0197 33.1878 44.612 34.988C44.2044 36.7881 43.3725 38.4641 42.186 39.8755C37.8841 45.1636 33.5815 50.4501 29.2781 55.735C27.4919 57.8989 24.9715 59.325 22.2008 59.7394C19.5392 60.1953 16.8097 60.0486 14.2119 59.3102C11.6142 58.5718 9.21393 57.2604 7.18689 55.4719C4.32599 53.2285 2.25208 50.1297 1.26586 46.6249C0.627895 44.6481 0.51684 42.5384 0.943728 40.5052C1.37062 38.4721 2.32059 36.5863 3.69929 35.0352C5.55687 32.8631 7.30995 30.6117 9.1118 28.393Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                </SvgIcon>
                <div style={{
                  width: '100%', height: '0px', margin: '0px 8px', borderBottom: '1px solid #D9D9D9',
                }}
                />
              </div>
              <PortalName>Servest IS Portal</PortalName>
              <input
                tabIndex={0}
                ref={usernameRef}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    if (password !== '') {
                      handleLogin();
                    } else {
                      passwordInputRef.current.focus();
                    }
                  }
                }}
                value={username}
                onChange={(event) => {
                  setUsername(event.target.value);
                  if (event.target.value && event.target.value !== '') {
                    usernameRef.current.classList.remove('invalid-input');
                    setOpenSnackbar(false);
                    setToastMessage('');
                  } else {
                    usernameRef.current.classList.add('invalid-input');
                  }
                }}
                placeholder="Username"
                type="text"
                name="j_username"
                id="j_username"
              />
              <PasswordContainer ref={passwordRef}>
                <input
                  ref={passwordInputRef}
                  tabIndex={0}
                  value={password}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleLogin();
                    }
                  }}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    if (event.target.value && event.target.value !== '') {
                      passwordRef.current.classList.remove('invalid-input');
                      setOpenSnackbar(false);
                      setToastMessage('');
                    } else {
                      passwordRef.current.classList.add('invalid-input');
                    }
                  }}
                  placeholder="Password"
                  type={!passwordShown ? 'password' : 'text'}
                  name="j_password"
                  id="j_password"
                />
                <PasswordVisibility
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    setPasswordShown(!passwordShown);
                  }}
                >
                  {passwordShown ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </PasswordVisibility>
              </PasswordContainer>
              <div style={{
                width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '8px',
              }}
              >
                <Link to="/register" style={{ width: '100%' }}>
                  <RegisterButton
                    tabIndex={0}
                    id="loginButton"
                    type="submit"
                    name="login"
                  >
                    Register a new Profile
                    <Ripple />
                  </RegisterButton>
                </Link>
                <LoginButton
                  tabIndex={0}
                  id="loginButton"
                  type="submit"
                  name="login"
                  onClick={() => {
                    handleLogin();
                  }}
                >
                  Sign In
                  <Ripple />
                </LoginButton>
              </div>
            </SlideIn>
            <StyleHr />
            <div style={{
              width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
            }}
            >
              <Material.Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  login.passwordReset(username, subscriberId);
                }}
                underline="always"
              >
                Forgot password?
              </Material.Link>
              <Material.Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  login.passwordHint(username, subscriberId);
                }}
                underline="always"
              >
                SMS Password Hint
              </Material.Link>
            </div>
          </StyleContainer>
        </InnerContainer>
        <Footer>
          <div
            style={{ paddingRight: '5px' }}
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net');
            }}
          >
            Powered by Forcelink&trade;
            {' '}
            {new Date().getFullYear()}
            {' '}
            |
          </div>
          <div
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net/privacy-policy');
            }}
          >
            Privacy Policy
          </div>
        </Footer>
      </OuterContainer>
    </LoginContainer>
  );
};

export default LoginForm;
