import styled from 'styled-components';

export const JoburgWaterAppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  background-color: #F2F2F2;

  #portalNav {
    width: calc(100% - 32px);
    background-color: #178eb4 !important;
    margin-bottom: 16px;
  }

  #App {
    width: 100%;
    height: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center
    align-items: center;
    max-height: 100%;
    overflow-y: scroll;
  }

  #AppContainer {
    padding: 0px;
    width: 100%;
    height: 100%;

    .slide-container {
      height: fit-content;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-x: hidden;
    }

    #formContainer {
      padding: 0px 16px;
    }

    #formCard {
      border: 1px solid #EBEBEB !important;
      width: 100%;

      @media screen and (min-width: 1200px) {
        width: 1000px;
      }

      .card-body {
        padding: 16px;
        
        #employeeCol {
          margin-bottom: 8px;
          justify-content: start;

          @media screen and (min-width: 600px) {
            margin-bottom: 0px;
            justify-content: center;
          }
        }

        #combo-box {
          padding: 0px;
          height: 100%;
          border: none;

          :hover {
            border: none;
          }

          :focus {
            border: none;
          }

          :active {
            border: none;
          }
        }

        .form {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
          
        .section {
          display: flex;
          flex-direction: column;
          gap: 20px;
          background-color: #fafafa;
          padding: 16px;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
        }

        .form-label {
          margin: 0px;
          font-weight: 500;
        }

        .col-md-6, .col-md-12 {
          display: flex;
          flex-direction: column;
          gap: 4px;
          padding: 0px;
        }

        .col-md-6 {
          flex: 0 0 100%;
          max-width: 100%;

          @media screen and (min-width: 768px) {
            flex: 0 0 calc(50% - 10px);
            max-width: calc(50% - 10px);
          }
        }

        .largeLabel {
          color: #178eb4;
          font-weight: 600;
          text-align: left;
          width: 100%;
          border-bottom: 1px solid;
          padding-bottom: 8px;
        }

        .smallLabel {
          color: #262626;
        }

        .map_canvas {
          margin: 0px;
        }

        .form-check-label {
          text-align: left;
        }

        .btn-outline-primary {
          background-color: #ffffff;
          border: 1px solid #178eb4;
          color: #178eb4;
          transition: color 0.3s, background-color 0.3s, border-color 0.3s;

          :hover {
            background-color: #178eb4;
            border: 1px solid #178eb4;
            color: #ffffff;
          }

          :focus {
            background-color: #178eb4;
            border: 1px solid #178eb4;
            color: #ffffff;
          }

          :active {
            background-color: #89bed9;
            border: 1px solid #89bed9;
          }
        }

        #helperText {
          text-align: left;
          margin: 0px;
          font-size: 12px;
          line-height: 12px;
          font-weight: 300;
        }

        .form-group {
          margin: 0px;
    
          .MuiFormLabel-root {
            top: -4px;
          }
    
          .MuiFormLabel-root.Mui-focused {
            top: 0px;
          }
        }

        .MuiFormControl-root {
          margin: 0px;
        }

        #combo-box-label.Mui-focused {
          color: #178eb4;
        }

        .MuiInputBase-root.Mui-focused  {
          fieldset {
            border-color: #178eb4;
            border-width: 2px;
          }
        }

        .MuiInputBase-root {
          height: 45px;
          border-radius: 0px;
          background-color: #ffffff;

          :hover {
            fieldset {
              border-color: #178eb4;
              border-width: 2px;
            }
          }
        }

        input[type=checkbox] {
          accent-color: #178eb4;
        }

        input[type=text], input[type=password], input[type=tel], input[type=email], input[type=number] {
          height: 45px;
        }

        textarea {
          height: 75px;
        }

        input[type=text], input[type=password], input[type=tel], input[type=email], input[type=number], textarea {
          border-radius: 0px;
          transition: border-color 0.3s;

          :focus {
            border: 2px solid #178eb4;
            outline: none !important;
            outline-offset: none !important;
            box-shadow: none !important;
          }

          :active {
            border: 2px solid #178eb4;
            outline: none !important;
            outline-offset: none !important;
            box-shadow: none !important;
          }

          :hover {
            border: 2px solid #178eb4;
          }
        }

        .form-row {
          margin: 5px;
        }

        .row {
          padding: 0px 16px;
          gap: 20px;
        }
      }

      .card-header, .card-footer  {
        padding: 16px 24px;
        background-color: #ffffff;
        margin: 0px 24px;
      }
    
      .card-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    
      .card-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0px;
    
        .MuiLink-root {
          color: #178eb4;
          font-weight: bold;
        }
      }

      .card-header {
        .login-title {
          font-weight: 500;
          line-height: 18px;
          font-size: 18px;
          padding-left: 16px;
    
          @media screen and (min-width: 800px) {
            line-height: 20px;
            font-size: 20px;
          }
        }
    
        .login-logo {
          margin: 0px;
          height: 50px;
          width: max-content;
          padding-right: 16px;
          border-right: 2px solid #000000;
    
          @media screen and (min-width: 800px) {
            height: 60px;
          }     
        }
      }

      .button-group {
        width: 100%;
        padding 16px 0px ;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    
        .form-group {
          margin: 8px 0px;
          width: 100%;
    
          button {
            width: 100%;
            height: 100%;
            padding: 8px 0px;
            border-radius: 0px;
            font-weight: bold;
            overflow: hidden;
            position: relative;
            transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    
            :focus {
              outline: none !important;
              outline-offset: none !important;
              box-shadow: none !important;
            }
            
            :active {
              outline: none !important;
              outline-offset: none !important;
              box-shadow: none !important;
            }
          }
        }
    
        .primary {
          background-color: #178eb4;
          border: 1px solid #178eb4;
  
          :hover {
            background-color: #89bed9;
            border: 1px solid #89bed9;
          }
  
          :focus {
            background-color: #89bed9;
            border: 1px solid #89bed9;
          }
  
          :active {
            background-color: #89bed9;
            border: 1px solid #89bed9;
          }
        }
    
        .secondary {
          background-color: #ffffff;
          color: #888888;
          border: 1px solid #888888;
    
          :hover {
            background-color: #EBEBEB;
            color: #888888;
          }
  
          :focus {
            background-color: #EBEBEB;
            color: #888888;
          }
  
          :active {
            background-color: #EBEBEB;
            color: #888888;
          }
        }

        #submit-spinner {
          width: 70%;
          margin-left: 8px;
        }

        a {
          width: 30%;
          margin-right: 8px;
        }
      }
    }

    .table-data-grid-container {
      background-color: unset !important;
    }

    .MuiDataGrid-root {
      background-color: #ffffff;
    }

    .table-wrapper, .table-data-grid-container {
      padding: 0px 16px;
    }

    #homePageContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 16px;

      .slide-container {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
      }

      .container {
        background-color: #ffffff;
        border: 1px solid #EBEBEB !important;
        padding: 24px;
        margin: 0px;
        max-height: 100%;
        overflow-y: scroll;

        #title-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding-bottom: 16px;
          border-bottom: 1px solid #EBEBEB;
        }

        .login-title {
          font-weight: 500;
          line-height: 20px;
          font-size: 20px;
          padding-left: 16px;
    
          @media screen and (min-width: 800px) {
            line-height: 25px;
            font-size: 25px;
          }
        }
    
        .login-logo {
          margin: 16px 0px;
          height: 50px;
          width: max-content;
          padding-right: 16px;
          border-right: 2px solid #000000;
    
          @media screen and (min-width: 800px) {
            height: 75px;
          }     
        }
        .iconRow {
          width: 100%;
          margin: 0px;
        }

        .iconGroup {

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 187px;
            height:187px;
          }

          #image-container {
            width: fit-content;
            height: fit-content;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            margin: 16px;

            opacity: 0.8;
            transition: opacity 0.2s, box-shadow 0.2s, width 0.2s, height 0.2s;

            .icons {
              transition: width 0.2s, height 0.2s;
            }

            :hover {
              opacity: 1;
              box-shadow: 0px 0px 50px #178eb480;

              .icons {
                width: clamp(155px, 0px, 0px) !important;
                height: clamp(155px, 0px, 0px) !important;
              }
            }
          }
        }
      }
    }
  }

  #LoginForm {
    padding: 0px 16px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    #loginAlertContainer {
      width: 100%;
      min-height: 130px;

      @media screen and (min-width: 400px) {
        min-height: 110px;
      }

      @media screen and (min-width: 450px) {
        min-height: 90px;
      }

      @media screen and (min-width: 650px) {
        min-height: 70px;
      }

      @media screen and (min-width: 1100px) {
        min-height: 50px;
      }

      #loginAlert {
        width: 100%;
        margin: 0px;
      }
    }
  }
}
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  font-weight: 650;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #000000;

  .link {
    :hover {
      text-decoration: underline;
    }
  }
`;

export const ImageDropStyle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 8px;

  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dashed #178eb4;
    border-radius: 4px;
    min-height: 100px;
    min-width: 100px;
    height: 100px;
    width: 100px;
    font-weight: 650;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #178eb4;
    background-color: #178eb433;
    cursor: pointer;
  }

  .work-image {
    padding: 8px;
    height: 100px;
    width: 100px;
    min-height: 100px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #178eb4;
    border-radius: 4px;
    position: relative;

    :hover {
      .control-delete {
        opacity: 1;
        pointer-events: all;
        cursor: pointer;
      }
    }

    .control-delete {
      position: absolute;
      transition: opacity 0.3s;
      opacity: 0;
      pointer-events: none;
      bottom: 10px;
      right: 10px;

      svg {
        color: #595959;
      }
    }

    img {
      max-height: 100%;
      max-width: 100%;
      vertical-align: middle;
    }
  }`;
