/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import {
  Row, Col, Container, Card, Form, Button,
} from 'react-bootstrap';
import * as Material from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CircularProgress as Spinner } from '@material-ui/core';
import { Context } from '../../functions/joburg_water/context';
import '../../styles/joburg_water/logFault.scss';
import { AddressInput } from '../../components/joburg_water/address_input';
import {
  getCallTypes,
  getSubCallTypes,
  logFaultMyAddress,
  logFaultOtherAddress,
} from '../../scripts/joburg_water/logFaultScripts';
import { handleLogging } from '../../functions/forms';
import Ripple from '../../components/joburg_water/ripple';
import SlideIn from '../../components/joburg_water/slidein';
import {
  getProfile,
} from '../../scripts/joburg_water/profileScripts';
import ImageDrop from '../../components/joburg_water/image_drop';
import { addWorkRequestAttachment } from '../../functions/apiCalls';
import DialogPopup from '../../components/joburg_water/dialog_popup';

class LogFault extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    const { otherAddress } = this.props;
    // requiredMissing: null = the input is not required (or not displayed yet)
    // false = the input is required and correctly filled in upon submission
    // true = the input is required and not filled in upon submission
    this.state = {
      entries: otherAddress ? this.initEntriesWithMap() : this.initEntries(),
      requiredMissing: otherAddress
        ? this.initRequiredMissingWithMap()
        : this.initRequiredMissing(),
      callTypesList: [],
      subCallTypesList: [],
      subSubCallTypesList: [],
      submitting: false,
      images: [],
      popupOpen: false,
      submissionResponse: undefined,
    };
  }

  initEntries = () => ({
    callType: '',
    subCallType: '',
    subSubCallType: '',
    callNotes: '',
    meterNumber: '',
    accountNumber: '',
  });

  initEntriesWithMap = () => ({
    ...this.initEntries(),
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
    standNumber: '',
    building: '',
    unitNumber: '',
    closesCrossStreet: '',
    description: '',
    latitude: 0.0,
    longitude: 0.0,
    orgUnitCode: '',
  });

  initRequiredMissing = () => ({
    callType: false,
    callNotes: false,
    accountNumber: false,
  });

  initRequiredMissingWithMap = () => ({
    callType: false,
    callNotes: false,
    contactPerson: false,
    mobileNumber: false,
    streetNumber: false,
    streetName: false,
    suburb: false,
  });

  componentDidMount = async () => {
    const {
      hasApiKey, email,
    } = this.context;
    const callTypesList = await this.generateCallTypes();
    if (hasApiKey) {
      this.setState((prevState) => ({
        entries: {
          ...prevState.entries,
          emailAddress: email,
        },
        requiredMissing: {
          ...prevState.requiredMissing,
          emailAddress: false,
        },
        callTypesList,
      }));
    } else {
      try {
        const profileData = await getProfile();
        const {
          mobileNumber, meterNumber, emailAddress, contactPerson, accountNumber,
        } = profileData;
        // Auto populate these fields with profile data
        this.setState((prevState) => ({
          entries: {
            ...prevState.entries,
            mobileNumber,
            meterNumber,
            emailAddress,
            contactPerson,
            accountNumber,
          },
          callTypesList,
        }));
      } catch (error) {
      // Handle the error here, e.g., show an error message to the user or log the error.
        console.error('Error fetching profile data:', error);
      }
    }
  };

  generateCallTypes = async () => {
    const { callTypes, setCallTypes } = this.context;
    console.log('callTypes:', callTypes);
    if (callTypes == null || callTypes.length === 0) {
      console.log('getting call types from API');
      const cT = (await getCallTypes()) || [];
      setCallTypes(cT);
      return cT;
    }
    console.log('getting call types from React context');
    return callTypes;
  };

  handleSubmit = async () => {
    const {
      hasApiKey,
    } = this.context;
    const { otherAddress } = this.props;
    const { entries, images } = this.state;
    this.setState({
      submitting: true,
    });
    if (
      handleLogging(this.state, (s) => this.setState(s)) === true
    ) {
      let valid = true;
      if (otherAddress) {
        const regexPhone = /^0\d{9}$/;
        const isValidNumber = regexPhone.test(entries.mobileNumber);
        if (!isValidNumber) {
          valid = false;
          alert('Invalid mobile number, please start with a 0 and provide a ten digit number.');
        } else if (entries.emailAddress !== undefined && entries.emailAddress !== null && entries.emailAddress !== '') {
          const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const isValidEmail = regexEmail.test(entries.emailAddress);
          if (!isValidEmail) {
            alert('Invalid email address.');
            valid = false;
          }
        }
      }
      if (valid) {
        let response = '';
        if (!otherAddress) {
          response = await logFaultMyAddress(entries,
            (s) => this.setState(s));
        }
        if (otherAddress) {
          let alteredEntries = { ...entries };
          if (hasApiKey) {
            alteredEntries = { ...alteredEntries, channel: 'c01' };
          }
          response = await logFaultOtherAddress(alteredEntries,
            (s) => this.setState(s));
        }
        if (response !== undefined && response !== '' && response.result !== 'FAILURE') {
          const uploadImages = async () => {
            const uploadPromises = images.map((image) => {
              const formData = new FormData();
              formData.append('workRequestId', response.id);
              formData.append('file', image);
              return addWorkRequestAttachment(formData, 'joburg_water');
            });
            await Promise.all(uploadPromises);
          };
          await uploadImages();
          this.setState({
            popupOpen: true,
            submissionResponse: response,
          });
        }
      }
    }
    this.setState({
      submitting: false,
    });
  }

  // UPDATE VALUES >>
  setEntries = (name, val) => {
    const { entries } = this.state;
    const toUpdate = { ...entries };
    toUpdate[name] = val;
    this.setState({ entries: toUpdate });
    return val;
  };

  updateEntries = (name, value) => this.setEntries(name, value);

  updateRequiredState = (name, bool) => {
    const { requiredMissing } = this.state;
    const toUpdate = { ...requiredMissing };
    toUpdate[name] = bool;
    this.setState({ requiredMissing: toUpdate });
  };

  initRequiredState = (name) => this.updateRequiredState(name, false);

  updateReqEntries = (name, value) => {
    const newVal = this.updateEntries(name, value);
    if (newVal !== '') {
      this.initRequiredState(name);
    }
  };
  // << UPDATE VALUES

  onResultFound = (result) => {
    const {
      streetNumber, streetName, city, postalCode, suburb, latitude, longitude, orgUnitCode,
    } = result;
    const { entries, requiredMissing } = this.state;
    const toUpdate = { ...entries };
    toUpdate.streetNumber = streetNumber || '';
    toUpdate.streetName = streetName || '';
    toUpdate.city = city || '';
    toUpdate.postalCode = postalCode || '';
    toUpdate.suburb = suburb || '';
    toUpdate.latitude = latitude || '';
    toUpdate.longitude = longitude || '';
    toUpdate.orgUnitCode = orgUnitCode || '';
    const toUpdateReq = { ...requiredMissing };
    ['streetNumber', 'streetName', 'suburb'].forEach((req) => {
      console.log(req, toUpdate[req], toUpdate[req] == null || toUpdate[req] === '');
      if (!(toUpdate[req] == null || toUpdate[req] === '')) {
        toUpdateReq[req] = false;
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      entries: toUpdate,
      requiredMissing: toUpdateReq,
    }));
  };

  validStyle = (val) => {
    if (val) { return { color: 'red' }; } return null;
  };

  render() {
    const { title, otherAddress } = this.props;
    const {
      entries,
      images,
      entries: {
        callType,
        subCallType,
        subSubCallType,
        callNotes,
        meterNumber,
        accountNumber,
        contactPerson,
        mobileNumber,
        emailAddress,
        alternateMobileNumber,
      },
      requiredMissing,
      callTypesList,
      subCallTypesList,
      subSubCallTypesList,
      submitting,
      popupOpen,
      submissionResponse,
    } = this.state;
    const {
      hasApiKey,
      clearState,
      clearCookies,
    } = this.context;
    console.log('state:', this.state);

    if (subCallTypesList && subCallTypesList.length !== 0) {
      // Initialise states for sub call input when there is a sub call necessary
      requiredMissing.subCallType == null
        && this.initRequiredState('subCallType');
    } else {
      // When sub call not necessary, reset states for sub call
      if (subCallType !== '') {
        this.setEntries('subCallType', '');
      }
      if (requiredMissing.subCallType != null) {
        this.updateRequiredState('subCallType', null);
      }
    }

    if (subSubCallTypesList && subSubCallTypesList.length !== 0) {
      // Initialise states for sub call input when there is a sub call necessary
      requiredMissing.subSubCallType == null
        && this.initRequiredState('subSubCallType');
    } else {
      // When sub call not necessary, reset states for sub call
      if (subSubCallType !== '') {
        this.setEntries('subSubCallType', '');
      }
      if (requiredMissing.subSubCallType != null) {
        this.updateRequiredState('subSubCallType', null);
      }
    }

    return (
      <Container fluid id="formContainer">
        {submissionResponse !== undefined && (
        <DialogPopup
          open={popupOpen}
          title="Fault successfully logged."
          description={`Reference: ${submissionResponse.code}`}
          information={hasApiKey ? 'Click continue to register a profile.' : 'Click continue to navigate to the home page.'}
          onClose={hasApiKey ? () => {
            this.setState({
              popupOpen: false,
            });
            clearCookies();
            localStorage.clear();
            clearState();
            window.location.href = '/joburg_water/register';
          } : () => {
            this.setState({
              popupOpen: false,
            });
            window.location.pathname = '/joburg_water/home';
          }}
        />
        )}
        <div className="slide-container">
          <SlideIn settings={{
            marginRight: 0,
            width: '100%',
            from: { marginRight: -700 },
            config: { mass: 1, tension: 300, friction: 30 },
          }}
          >
            <Card id="formCard" className="text-center">
              <Card.Header>
                <img src="joburg_water/logo.jpg" className="login-logo" alt="Joburg Water Logo" />
                <div className="login-title">
                  {hasApiKey ? 'Log a fault' : title}
                </div>
              </Card.Header>
              <Card.Body id="formBody">
                <Form className="form">
                  {otherAddress
                  && (
                  <div className="section">
                    <Form.Group className="formLabels">
                      <Form.Label className="largeLabel">
                        Instructions
                      </Form.Label>
                    </Form.Group>
                    <div
                      css={css`
                        color: #262626;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                      `}
                    >
                      <div
                        css={css`
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        color: #262626;

                        ul {
                          list-style-type: none;
                          padding: 0px;
                          margin: 0px;
                        }

                        li {
                          color: #262626;
                        }

                        .empty-line {
                          height: 12px;
                        }
                      `}
                      >
                        <span
                          css={css`
                            font-weight: bold;
                            color: #262626;
                          `}
                        >
                          Please fill in the form below:
                        </span>
                        <span className="empty-line" />
                        <ul>
                          <li>
                            <span
                              css={css`
                                font-weight: 600;
                                color: #262626;
                              `}
                            >
                              Step 1:
                            </span>
                            {' '}
                            Tell us about your fault.
                          </li>
                          <li>
                            <span
                              css={css`
                                font-weight: 600;
                                color: #262626;
                              `}
                            >
                              Step 2:
                            </span>
                            {' '}
                            Tell us how to contact you about your logged fault.
                          </li>
                          <li>
                            <span
                              css={css`
                                font-weight: 600;
                                color: #262626;
                              `}
                            >
                              Step 3:
                            </span>
                            {' '}
                            Tell us where your fault is.
                          </li>
                        </ul>
                      </div>
                      <div
                        css={css`
                        text-align: left;
                        color: #6c757d;  
                      `}
                      >
                        {' '}
                        <span
                          css={css`
                                font-weight: 600;  
                              `}
                        >
                          Please note:
                        </span>
                        {' '}
                        Required fields are indicated by a red asterisk (
                        <span css={css`color: red`}>*</span>
                        )
                      </div>
                    </div>
                  </div>
                  )}
                  <div className="section">
                    <Form.Group className="formLabels">
                      <Form.Label className="largeLabel">
                        Fault Information
                      </Form.Label>
                    </Form.Group>
                    <Row className="formInputs">
                      {/* CALL TYPE */}
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle(requiredMissing.callType === true)}
                          >
                            Level One Fault Type
                            {' '}
                            <span css={css`color: red`}>*</span>
                          </Form.Label>
                        </Form.Group>
                        <Material.TextField
                          name="callType"
                          select
                          fullWidth
                          margin="normal"
                          label=""
                          variant="outlined"
                          required
                          error={requiredMissing.callType === true}
                          value={callType}
                          onChange={async (e) => {
                            this.updateReqEntries(e.target.name, e.target.value);
                            const callTypeCode = e.target.value;
                            const res = await getSubCallTypes(callTypeCode);
                            this.setState({
                              subCallTypesList: res.filter(
                                (item) => item.value !== callTypeCode,
                              ),
                            });
                          }}
                        >
                          <Material.MenuItem
                            key="blank"
                            value=""
                          />
                          {callTypesList.map((type) => (
                            <Material.MenuItem
                              key={type.value}
                              value={type.value}
                            >
                              {type.label}
                            </Material.MenuItem>
                          ))}
                        </Material.TextField>
                      </Col>
                      {/* SUB CALL TYPE */}
                      {subCallTypesList && subCallTypesList.length !== 0 && (
                      <Col md={6}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle(requiredMissing.subCallType === true)}
                          >
                            Level Two Fault Type
                            {' '}
                            <span css={css`color: red`}>*</span>
                          </Form.Label>
                        </Form.Group>
                        <Material.TextField
                          name="subCallType"
                          select
                          fullWidth
                          margin="normal"
                          label=""
                          variant="outlined"
                          required
                          error={requiredMissing.subCallType === true}
                          value={subCallType}
                          onChange={async (e) => {
                            this.updateReqEntries(e.target.name, e.target.value);
                            const callTypeCode = e.target.value;
                            const res = await getSubCallTypes(callTypeCode);
                            this.setState({
                              subSubCallTypesList: res.filter(
                                (item) => item.value !== callTypeCode
                                    && item.value !== callType,
                              ),
                            });
                          }}
                        >
                          {subCallTypesList.map((type) => (
                            <Material.MenuItem
                              key={type.value}
                              value={type.value}
                            >
                              {type.label.replaceAll('&nbsp;', '')}
                            </Material.MenuItem>
                          ))}
                        </Material.TextField>
                      </Col>
                      )}
                    </Row>
                    {subSubCallTypesList && subSubCallTypesList.length !== 0 && (
                      <Row className="formInputs">
                        {/* SUB SUB CALL TYPE */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={this.validStyle(requiredMissing.subSubCallType === true)}
                            >
                              Level Three Fault Type
                              {' '}
                              <span css={css`color: red`}>*</span>
                            </Form.Label>
                          </Form.Group>
                          <Material.TextField
                            name="subSubCallType"
                            select
                            fullWidth
                            margin="normal"
                            label=""
                            variant="outlined"
                            required
                            error={requiredMissing.subSubCallType === true}
                            value={subSubCallType}
                            onChange={
                              (e) => {
                                this.updateReqEntries(e.target.name, e.target.value);
                              }
                            }
                          >
                            {subSubCallTypesList.map((type) => (
                              <Material.MenuItem
                                key={type.value}
                                value={type.value}
                              >
                                {type.label.replaceAll('&nbsp;', '')}
                              </Material.MenuItem>
                            ))}
                          </Material.TextField>
                        </Col>
                      </Row>
                    )}
                    {/* CALL NOTES */}
                    <Row className="formInputs">
                      <Col md={12}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                            style={this.validStyle(requiredMissing.callNotes === true)}
                          >
                            Fault Notes
                            {' '}
                            <span css={css`color: red`}>*</span>
                          </Form.Label>
                        </Form.Group>
                        <Form.Control
                          name="callNotes"
                          aria-describedby="helperText"
                          as="textarea"
                          rows={3}
                          required
                          isInvalid={requiredMissing.callNotes === true}
                          value={callNotes}
                          onChange={
                              (e) => {
                                this.updateReqEntries(e.target.name, e.target.value);
                              }
                            }
                        />
                        <Form.Text muted id="helperText">
                          Please provide any additional information you may have.
                        </Form.Text>
                      </Col>
                    </Row>
                    <Form.Group>
                      <Row className="formInputs">
                        {/* ACCOUNT NUMBER */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label
                              className="smallLabel"
                              style={this.validStyle(requiredMissing.accountNumber === true)}
                            >
                              Account Number
                              {' '}
                              {!otherAddress && <span css={css`color: red`}>*</span>}
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            name="accountNumber"
                            type="text"
                            isInvalid={requiredMissing.accountNumber === true}
                            value={accountNumber}
                            onChange={(e) => {
                              this.updateReqEntries(e.target.name, e.target.value);
                            }}
                          />
                        </Col>
                        {/* METER NUMBER */}
                        <Col md={6}>
                          <Form.Group className="formLabels">
                            <Form.Label className="smallLabel">
                              Meter Number
                            </Form.Label>
                          </Form.Group>
                          <Form.Control
                            name="meterNumber"
                            type="text"
                            value={meterNumber}
                            onChange={(e) => {
                              this.updateEntries(e.target.name, e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Row className="formInputs">
                      {/* ATTACHMENTS */}
                      <Col md={12}>
                        <Form.Group className="formLabels">
                          <Form.Label
                            className="smallLabel"
                          >
                            Attachments
                          </Form.Label>
                        </Form.Group>
                        <ImageDrop
                          workImages={undefined}
                          images={images}
                          setImages={(value) => { this.setState({ images: value }); }}
                          workRequestId={undefined}
                          refresh={undefined}
                        />
                        <Form.Text muted id="helperText">
                          To include a document or picture related
                          to your fault please press the upload button.
                        </Form.Text>
                      </Col>
                    </Row>
                  </div>
                  {otherAddress && (
                    <>
                      <div className="section">
                        <Form.Group className="formLabels">
                          <Form.Label className="largeLabel">
                            Contact Details
                          </Form.Label>
                        </Form.Group>
                        <Form.Group>
                          <Row className="formInputs">
                            {/* CONTACT PERSON */}
                            <Col md={6}>
                              <Form.Group className="formLabels">
                                <Form.Label
                                  className="smallLabel"
                                  style={this.validStyle(requiredMissing.contactPerson === true)}
                                >
                                  Contact Person
                                  {' '}
                                  <span css={css`color: red`}>*</span>
                                </Form.Label>
                              </Form.Group>
                              <Form.Control
                                name="contactPerson"
                                type="text"
                                required
                                isInvalid={requiredMissing.contactPerson === true}
                                value={contactPerson}
                                onChange={
                                (e) => {
                                  this.updateReqEntries(e.target.name, e.target.value);
                                }
                              }
                              />
                            </Col>
                            {/* CONTACT NUMBER */}
                            <Col md={6}>
                              <Form.Group className="formLabels">
                                <Form.Label
                                  className="smallLabel"
                                  style={this.validStyle(requiredMissing.mobileNumber === true)}
                                >
                                  Contact Number
                                  {' '}
                                  <span css={css`color: red`}>*</span>
                                </Form.Label>
                              </Form.Group>
                              <Form.Control
                                name="mobileNumber"
                                type="number"
                                required
                                isInvalid={requiredMissing.mobileNumber === true}
                                value={mobileNumber}
                                onChange={
                                (e) => {
                                  this.updateReqEntries(e.target.name, e.target.value);
                                }
                              }
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row className="formInputs">
                            {/* CONTACT EMAIL */}
                            <Col xs={12} md={6}>
                              <Form.Group className="formLabels">
                                <Form.Label
                                  className="smallLabel"
                                  style={this.validStyle(requiredMissing.emailAddress === true)}
                                >
                                  Contact Email
                                  {' '}
                                  {hasApiKey && <span css={css`color: red`}>*</span>}
                                </Form.Label>
                              </Form.Group>
                              <Form.Control
                                name="emailAddress"
                                type="email"
                                isInvalid={requiredMissing.emailAddress === true}
                                value={emailAddress}
                                onChange={(e) => {
                                  this.updateEntries(e.target.name, e.target.value);
                                }}
                              />
                            </Col>
                            {/* ALTERNATE CONTACT NUMBER */}
                            <Col md={6}>
                              <Form.Group className="formLabels">
                                <Form.Label className="smallLabel">
                                  Alternate Contact Number
                                </Form.Label>
                              </Form.Group>
                              <Form.Control
                                name="alternateMobileNumber"
                                type="number"
                                value={alternateMobileNumber}
                                onChange={
                                (e) => {
                                  this.updateReqEntries(e.target.name, e.target.value);
                                }
                              }
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                      <AddressInput
                        entries={entries}
                        requiredMissing={requiredMissing}
                        updateReqEntries={(name, value) => {
                          this.updateReqEntries(name, value);
                        }}
                        updateEntries={(name, value) => {
                          this.updateEntries(name, value);
                        }}
                        onResultFound={this.onResultFound}
                        page="logFault"
                      />
                    </>
                  )}
                </Form>
              </Card.Body>
              <Card.Footer>
                <div className="button-group">
                  <Form.Group id="finalButtons">
                    {!hasApiKey
                    && (
                    <Link to="/home">
                      <Button className="secondary" type="submit">
                        Cancel
                        <Ripple />
                      </Button>
                    </Link>
                    )}
                    <div
                      id="submit-spinner"
                      css={css`
                        ${hasApiKey ? css`
                          width: 100% !important;
                        ` : css`
                          width: 70% !important;
                          margin-left: 8px;`}
                      `}
                    >
                      {!submitting && (
                        <Button
                          type="submit"
                          className="primary"
                          onClick={this.handleSubmit}
                        >
                          {hasApiKey ? 'Submit' : 'Save and continue' }
                          <Ripple />
                        </Button>
                      )}
                      {submitting && <Spinner />}
                    </div>
                  </Form.Group>
                </div>
              </Card.Footer>
            </Card>
          </SlideIn>
        </div>
      </Container>
    );
  }
}

export const LogFaultMyAddress = () => <LogFault title="Log a fault at My Address" />;

export const LogFaultOtherAddress = () => <LogFault title="Log a fault at Another Address" otherAddress />;
