/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { Controller, useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import {
  Row,
} from 'react-bootstrap';
import { getCustomersForParent } from '../../functions/apiCalls';

const CustomerSelector = ({ customers, onSelectionChange, workOrderRequest }) => {
  const { control, setValue, watch } = useForm({
    defaultValues: {
      customerCode: '',
      childCustomerCode: '',
    },
  });

  const [childCustomers, setChildCustomers] = useState([]);
  const [selectText, setSelectText] = useState(
    workOrderRequest?.code === undefined
      ? 'Select'
      : workOrderRequest.customerDescription,
  );
  console.log('selectText', selectText);
  const customerCode = watch('customerCode');
  const childCustomerCode = watch('childCustomerCode');

  // Fetch child customers when a parent is selected
  const getChildCustomers = async (id) => {
    const children = await getCustomersForParent(id).then((res) => res.json());
    setChildCustomers(children || []);
  };

  useEffect(() => {
    if (workOrderRequest?.code) {
      setSelectText(workOrderRequest.customerDescription);
    } else {
      setSelectText('Select'); // Fallback to 'Select' if no description
    }
  }, [workOrderRequest?.customerDescription]);

  // Reset child selection & fetch children when a new parent is selected
  useEffect(() => {
    if (customerCode) {
      setValue('childCustomerCode', '');
      getChildCustomers(customerCode);
      const selectedCustomer = customers.find((c) => c.value === customerCode);
      onSelectionChange(selectedCustomer);
    }
  }, [customerCode]);

  // Notify parent component when a child is selected
  useEffect(() => {
    if (childCustomerCode) {
      const selectedChild = childCustomers.find((c) => c.value === childCustomerCode);
      onSelectionChange(selectedChild);
    }
  }, [childCustomerCode]);

  return (
    <div>
      {/* Parent Customer Dropdown */}
      <Controller
        control={control}
        name="customerCode"
        render={({ field }) => (
          <Form.Control
            as="select"
            {...field}
            value={field.value || ''}
            onChange={(e) => {
              field.onChange(e);
              setChildCustomers([]);
              const selectedCustomer = customers.find((c) => c.value === e.target.value);
              onSelectionChange(selectedCustomer);
              setSelectText('Select'); // Reset the text to "Select" when user selects a new customer
            }}
          >
            <option disabled value="">{selectText}</option>
            {customers.map(({ value, label, disabled }) => (
              <option key={value} disabled={disabled} value={value}>
                {label}
              </option>
            ))}
          </Form.Control>
        )}
      />
      {/* Child Customer Dropdown */}
      {childCustomers.length > 0 && (
        <Row css={css`margin-top: 16px !important;`}>
          <Controller
            control={control}
            name="childCustomerCode"
            render={({ field }) => (
              <Form.Control
                as="select"
                {...field}
                value={field.value || ''}
                onChange={(e) => {
                  field.onChange(e);
                  const selectedChild = childCustomers.find((c) => c.value === e.target.value);
                  onSelectionChange(selectedChild);
                }}
              >
                <option value="select">Select</option>
                {childCustomers.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
            )}
          />
        </Row>
      )}
    </div>
  );
};

export default CustomerSelector;
