import React, {
  useState, useRef,
} from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  Box,
  LinearProgress,
} from '@mui/material';
import AlertBar from './alertBar';
import {
  LoginContainer,
  InnerContainer,
  SubscriberImage,
  OuterContainer,
  StyleContainer,
  PasswordVisibility,
  PasswordContainer,
  LoginButton,
  StyleHr,
  Footer,
} from '../../pages/joshco/login/login.styled';
import * as login from '../../scripts/loginScripts';
import SlideIn from './slidein';
import Ripple from './ripple';

const LoginForm = (props) => {
  const { setAuth, subscriberId } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [validSubmit, setValidSubmit] = useState(true);
  const [loading, setLoading] = useState(false);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordInputRef = useRef(null);

  const handleLogin = () => {
    setLoading(true);
    if (username === '' && password === '') {
      setOpenSnackbar(true);
      setValidSubmit(false);
      setToastMessage('Username and Password required');
      usernameRef.current.classList.add('invalid-input');
      passwordRef.current.classList.add('invalid-input');
      setLoading(false);
    } else if (username === '') {
      setOpenSnackbar(true);
      setValidSubmit(false);
      setToastMessage('Username required');
      usernameRef.current.classList.add('invalid-input');
      setLoading(false);
    } else if (password === '') {
      setOpenSnackbar(true);
      setValidSubmit(false);
      setToastMessage('Password required');
      passwordRef.current.classList.add('invalid-input');
      setLoading(false);
    } else {
      const isLoggedin = async () => {
        const success = await login.doLoginNoAlerts(username, password, setAuth, subscriberId);
        setLoading(false);
        if (!success) {
          setOpenSnackbar(true);
          setValidSubmit(false);
          setToastMessage('Could not log in');
        }
      };
      isLoggedin();
    }
  };

  return (
    <LoginContainer>
      <AlertBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        toastMessage={toastMessage}
        validSubmit={validSubmit}
      />
      <OuterContainer>
        <div />
        <InnerContainer
          style={{ opacity: loading ? 0.7 : 1, pointerEvents: loading && 'none' }}
        >
          <StyleContainer>
            <Box sx={{ width: 'calc(100% + 48px)', marginBottom: '40px' }}>
              {loading && <LinearProgress sx={{ color: '#102b7c' }} />}
            </Box>
            <SlideIn settings={{
              marginRight: 0,
              width: '100%',
              from: { marginRight: -450 },
              config: { mass: 1, tension: 300, friction: 30 },
            }}
            >
              <SubscriberImage />
              <input
                tabIndex={0}
                ref={usernameRef}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    if (password !== '') {
                      handleLogin();
                    } else {
                      passwordInputRef.current.focus();
                    }
                  }
                }}
                value={username}
                onChange={(event) => {
                  setUsername(event.target.value);
                  if (event.target.value && event.target.value !== '') {
                    usernameRef.current.classList.remove('invalid-input');
                    setOpenSnackbar(false);
                    setToastMessage('');
                  } else {
                    usernameRef.current.classList.add('invalid-input');
                  }
                }}
                placeholder="Username"
                type="text"
                name="j_username"
                id="j_username"
              />
              <PasswordContainer ref={passwordRef}>
                <input
                  ref={passwordInputRef}
                  tabIndex={0}
                  value={password}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleLogin();
                    }
                  }}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    if (event.target.value && event.target.value !== '') {
                      passwordRef.current.classList.remove('invalid-input');
                      setOpenSnackbar(false);
                      setToastMessage('');
                    } else {
                      passwordRef.current.classList.add('invalid-input');
                    }
                  }}
                  placeholder="Password"
                  type={!passwordShown ? 'password' : 'text'}
                  name="j_password"
                  id="j_password"
                />
                <PasswordVisibility
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    setPasswordShown(!passwordShown);
                  }}
                >
                  {passwordShown ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </PasswordVisibility>
              </PasswordContainer>
              <LoginButton
                tabIndex={0}
                id="loginButton"
                type="submit"
                name="login"
                onClick={() => {
                  handleLogin();
                }}
              >
                Sign In
                <Ripple />
              </LoginButton>
            </SlideIn>
            <StyleHr />
          </StyleContainer>
        </InnerContainer>
        <Footer>
          <div
            style={{ paddingRight: '5px' }}
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net');
            }}
          >
            Powered by Forcelink&trade;
            {' '}
            {new Date().getFullYear()}
            {' '}
            |
          </div>
          <div
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open('https://www.forcelink.net/privacy-policy');
            }}
          >
            Privacy Policy
          </div>
        </Footer>
      </OuterContainer>
    </LoginContainer>
  );
};

export default LoginForm;
