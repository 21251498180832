import React from 'react';
import { Cookies } from 'react-cookie';
import { getAllLabels, fetchApi } from '../apiCalls';
import * as context from '../context';
import { apiUrl } from '../../constants/constantsGlobal';

export const Context = React.createContext();

const checkValidApiKey = async () => fetchApi(`${apiUrl}subscriberdatabasemanager/getSubscriberId`, 'GET', {
  credentials: 'include',
})
  .then((res) => res)
  .catch(() => ({ status: 401 }));

export class ContextManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.zeroState();
    this.cookies = new Cookies();
  }

  zeroState = () => ({
    authenticated: null,
    callTypes: [],
    username: '',
    customerTypes: [],
    labels: {},
    hasApiKey: false,
    email: '',
  });

  componentDidMount = async () => {
    await this.checkAuth();
  };

  setAuth = async (...args) => context.setAuth(...args, this.cookies, (s) => this.setState(s));

  logout = () => context.logout((s) => this.setState(s), this.cookies, this.zeroState);

  clearCookies = () => context.clearCookies(this.cookies);

  clearState = () => this.setState(this.zeroState);

  setLabels = async () => {
    const labels = await getAllLabels();
    if (labels) this.setState({ labels });
  };

  checkAuth = async () => {
    console.log('cookies:', this.cookies);
    const cookiesList = Object.keys(this.cookies.getAll());
    console.log('cookies list:', cookiesList);
    const authenticated = cookiesList.includes('SESSION');
    const username = this.cookies.get('username');
    let toSetState = { authenticated };
    if (username != null && username !== '') { toSetState = { ...toSetState, username }; }
    const queryParameters = new URLSearchParams(window.location.search);
    const anonymous = queryParameters.get('anonymous');
    const from = queryParameters.get('from');
    if (anonymous && from !== null) {
      localStorage.setItem('anonymous', anonymous);
      localStorage.setItem('subscriberId', 'joburg_water');
      const validApiKey = await checkValidApiKey();
      if (validApiKey.status === 200) {
        toSetState = {
          ...toSetState, authenticated: true, hasApiKey: true, email: from,
        };
      }
    } else {
      localStorage.removeItem('anonymous');
    }
    this.setState(toSetState);
    setTimeout(() => console.log('cookies after waiting 3s:', cookiesList), 3000);
  }

  // SETTERS
  setCallTypes = (callTypes) => this.setState({ callTypes });

  setCustomerTypes = (customerTypes) => this.setState({ customerTypes });

  // RENDER CONTEXT
  render() {
    const {
      props: { children },
      state: {
        authenticated, username, callTypes, customerTypes, labels, hasApiKey, email,
      },
      cookies,
      setAuth,
      logout,
      setCallTypes,
      setCustomerTypes,
      setLabels,
      clearState,
      clearCookies,
    } = this;

    // if (authenticated === null) clearCookies(this.cookies);
    if (authenticated === true) {
      cookies.set('subscriberId', 'joburg_water', { path: '/joburg_water' });
      if (Object.keys(labels).length === 0) {
        setLabels();
      }
    }

    console.log('context state:', this.state, 'cookies:', cookies.cookies);

    return (
      <Context.Provider
        value={{
          setAuth,
          logout,
          setCallTypes,
          setCustomerTypes,
          cookies,
          authenticated,
          username,
          callTypes,
          customerTypes,
          labels,
          hasApiKey,
          email,
          clearState,
          clearCookies,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}
