import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
} from 'react';
import {
  DataGrid,
  GridOverlay,
  GridToolbarContainer,
} from '@material-ui/data-grid';
import {
  Button,
  ButtonGroup,
  Checkbox,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { debounce } from '../functions/debounce';
import { fetchTable, getTableRowCount } from '../scripts/tablesScripts';
import {
  exportTableData, getExportedFiles, getExportFileDownload, getExportProgress,
} from '../functions/apiCalls';

/**
 * Material-design loading animation in overlay
 */
const CustomLoadingOverlay = () => (
  <GridOverlay>
    <div style={{ position: 'absolute', top: 0, width: '100%' }}>
      <LinearProgress />
    </div>
  </GridOverlay>
);

/**
 * Toolbar links for row
 */
const ToolbarLinks = ({ rowSelected, rowToolbarLinks }) => {
  console.log({ rowSelected, rowToolbarLinks });
  if (rowSelected && rowToolbarLinks) {
    return (
      <ButtonGroup
        size="small"
        variant="outlined"
      >
        {rowToolbarLinks.map(({ url, text, toState = false }) => (
          <Button
            component={Link}
            to={{
              pathname: toState ? url : `${url}/${rowSelected}`,
              state: toState ? { value: rowSelected } : null,
            }}
          >
            {text}
          </Button>
        ))}
      </ButtonGroup>
    );
  }
  return null;
};

/**
 * Table Toolbar with title and row links
 */
const CustomToolbar = ({ title, rowSelected, rowToolbarLinks }) => (
  <GridToolbarContainer
    style={{ display: 'flex' }}
  >
    <ToolbarLinks rowSelected={rowSelected} rowToolbarLinks={rowToolbarLinks} />
    <div style={{ flex: 1, textAlign: 'center' }}>
      <Typography variant="h6">{title}</Typography>
    </div>
  </GridToolbarContainer>
);

/**
 *
 * Custom Column Definitions: add renderCell, types, descriptions etc
 *
 */

/**
 * Datetime cell
 */
export const DateTimeColDef = (width = 100) => ({
  width,
  type: 'dateTime',
  valueGetter: ({ value }) => value && moment.unix(value / 1000).format('DD/MM/yyyy'),
});

/**
 * Checkbox cell
 */
export const CheckboxColDef = (width = 100) => ({
  width,
  renderCell: (params) => (
    <Checkbox
      checked={params.value}
      disableRipple
      style={{ pointerEvents: 'none' }}
      color="primary"
    />
  ),
});

/**
 * Link cell, e.g. /{url}/{id}
 */
export const LinkColDef = (width = 150, url) => ({
  width,
  renderCell: (params) => (
    <Link
      to={`${url}${params.row.code}`}
    >
      {params.value}
    </Link>
  ),
});

/**
 * String cell with width
 */
export const WidthColDef = (width = 100) => ({ width });

/**
 * String cell with flex width
 */
export const FlexColDef = (flex = 0.2) => ({ flex });

/**
 * Table component
 *  - Server-side filtering and pagination
 *  - Row toolbar links
 * @param props - {title,rowToolbarLinks,tableModelClass,id,
 * columnView,widths,colDefs,filters,additionalFilters,showExport }
 */
export const Table = ({
  Context, title, rowToolbarLinks, tableModelClass,
  id, columnView, filters, additionalFilters, additionalFromClauses = [], sorts = {},
  isNotCodeLink, isFault, showExport = false,
}) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowStart, setRowStart] = useState(0);
  const [rowEnd, setRowEnd] = useState(20);
  const [rowCount, setRowCount] = useState(10000);
  const [filter, setFilter] = useState(filters);
  const sort = sorts;
  const [loading, setLoading] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);
  const [exportLimit, setExportLimit] = useState(null);
  const [exportInProgress, setExportInProgress] = useState(false);

  const { labels } = useContext(Context);

  const PAGE_SIZE = 20;

  const startCheckExportProgress = async () => {
    let intervalId;

    // Function to check the progress
    const checkExportProgress = async () => {
      try {
        // Fetch export progress data
        const progressResponse = await getExportProgress();

        // Check if the response is an array (should be based on your backend response)
        if (Array.isArray(progressResponse) && progressResponse.length === 0) {
          clearInterval(intervalId);
          const exportedFiles = await getExportedFiles();
          if (exportedFiles.length > 0) {
            // Store the latest file
            const file = exportedFiles[0];
            // Download the file
            try {
              const i = file.url.indexOf('TableExports');
              const relativeUrl = i !== -1 ? file.url.slice(i) : null;
              const blob = await getExportFileDownload(relativeUrl);

              if (!blob || blob.size === 0) {
                throw new Error('Downloaded file is empty or invalid.');
              }

              // Create a temporary URL
              const downloadUrl = URL.createObjectURL(blob);

              // Create a download link
              const a = document.createElement('a');
              a.href = downloadUrl;
              a.download = file.fileName || 'downloaded_file';
              document.body.appendChild(a);
              a.click();

              // Clean up
              document.body.removeChild(a);
              URL.revokeObjectURL(downloadUrl);
            } catch (error) {
              console.error('Error downloading file:', error);
            }
            setExportInProgress(false);
          }
        } else {
          console.warn('No exports found');
        }
      } catch (error) {
        console.error('Error fetching export progress:', error);
      }
    };

    // Start polling every 5 seconds
    intervalId = setInterval(checkExportProgress, 5000);

    // Stop polling after a maximum duration
    setTimeout(() => clearInterval(intervalId), 600000);
  };

  /**
 * Call to export table data on export button click
 */
  const handleExport = async () => {
    try {
      setExportInProgress(true);
      const exportLimitObject = {
        className: 'LimitImpl',
        modelClass: exportLimit.modelClass,
        id: exportLimit.id,
        columnView: exportLimit.columnView,
        rowSelect: {
          rowStart: exportLimit.rowSelect.rowStart,
          rowEnd: exportLimit.rowSelect.rowEnd,
          maxRows: exportLimit.rowSelect.maxRows,
        },
        filterSet: {
          filters: [
            ...exportLimit.filterSet.filters,
          ],
        },
        sortSet: exportLimit.sortSet,
      };

      await exportTableData(exportLimitObject);
      startCheckExportProgress();
    } catch (error) {
      console.error('Export failed:', error);
      setExportInProgress(false);
    }
  };

  /**
   * Creates DataGrid columns, with custom column definitions (i.e. renderCell, type, etc)
   */
  const getDataGridColumns = (columnProps, model, colDefs) => {
    const headerNames = [];

    columnProps.forEach((item) => {
      const key = `${model}.${item}`;
      const label = labels[key] || key;
      headerNames.push(label);
    });

    return columnProps.map((item, index) => {
      const column = {
        field: item,
        headerName: headerNames[index],
        ...colDefs[item],
      };
      if (
        item.includes('time')
        || item.includes('Time')
        || item.includes('appointment')
      ) {
        column.type = 'dateTime';
      } else if (
        item.includes('date')
        || item.includes('Date')
      ) {
        column.type = 'date';
      }
      return column;
    });
  };

  const handleFilterChange = useCallback((filterModel) => {
    let mappedFilters = [];
    if (filterModel) {
      mappedFilters = filterModel.items.map((filterer) => ({
        property: filterer.columnField,
        value: filterer.value,
        options: [],
      }));
    }
    const filterArray = [...filter, ...mappedFilters];
    setFilter(filterArray);
  }, []);

  const handlePageChange = (p) => {
    const pageNo = p;
    console.log('pagination datagrid', pageNo);
    console.log('pagination state', page);
    setRowStart(pageNo * 20);
    setRowEnd(pageNo * 20 + 20);
    setPage(pageNo);
  };

  const handleRowSelected = (params) => {
    console.log('params', params.data.id);
    setRowSelected(params.data.id);
  };

  /**
   * returns col defs to use in generating a table
   * @param {*} columnProperties
   * @returns
   */
  const getFormattedColDefs = (columnProperties, columnWidths) => {
    const colDefs = {};
    for (let i = 0; i < columnProperties.length; i++) {
      let columnSize = columnWidths[columnProperties[i]];
      if (columnSize === undefined) {
        columnSize = 100;
      }
      if (columnProperties[i] === 'code' && !isNotCodeLink) {
        colDefs[columnProperties[i]] = LinkColDef(columnSize, tableModelClass === 'WorkOrder' ? 'workorder/' : 'ticket/');
      } else if (columnProperties[i] === 'code' && tableModelClass === 'WorkOrderRequest' && isFault) {
        colDefs[columnProperties[i]] = LinkColDef(columnSize, 'fault/');
      } else if (columnProperties[i].toLowerCase().includes('date') || columnProperties[i].toLowerCase().includes('actualfinish')) {
        colDefs[columnProperties[i]] = DateTimeColDef(columnSize);
      } else {
        colDefs[columnProperties[i]] = WidthColDef(columnSize);
      }
    }
    return colDefs;
  };

  useEffect(() => {
    let active = true;

    async function fetchData() {
      setLoading(true);

      const limitObject = {
        modelClass: tableModelClass,
        id,
        columnView,
        rowSelect: {
          rowStart,
          rowEnd,
          maxRows: rowCount,
        },
        additionalFromClauses,
        filterSet: {
          filters: additionalFilters !== undefined ? [...filters, ...additionalFilters] : filters,
        },
        sortSet: {
          sorts: [sort],
        },
      };

      setExportLimit(limitObject);

      const rowCountRes = await getTableRowCount(limitObject);
      // server bug, sorting does not seem to work if maxRows is not set
      limitObject.rowSelect.maxRows = await rowCountRes;

      const data = await fetchTable(limitObject);
      if (data) {
        const { limit, table } = data;
        if (limit !== undefined) {
          const { columnProperties, columnWidths } = limit;
          // Custom dynamic column width rendering.
          const index = columnProperties.indexOf('selected');
          if (index !== -1) {
            columnProperties.splice(index, 1);
          }
          const colDefs = getFormattedColDefs(columnProperties, { ...columnWidths });
          const datagridColumns = getDataGridColumns(
            columnProperties,
            tableModelClass,
            colDefs,
          );
          console.log('datagridColumns', datagridColumns);
          console.log('table', table);

          if (!active) {
            return;
          }

          setRows(table);
          setColumns(datagridColumns);
          setRowCount(rowCountRes);
          setLoading(false);
        }
      }
    }

    fetchData();

    return () => {
      active = false;
    };
  }, [page, filter, labels, additionalFilters]);

  return (
    <div className="table-data-grid-container" style={{ height: '70vh', width: '100%', backgroundColor: '#fff' }}>
      <div style={{
        display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', alignItems: 'center',
      }}
      >
        {showExport && (
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleExport}
            disabled={exportInProgress}
            style={{
              padding: '10px 20px', marginRight: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center', width: '110px',
            }}
          >
            Export
            {exportInProgress && <CircularProgress size={16} style={{ marginLeft: '10px' }} />}
          </Button>
        )}
      </div>
      <DataGrid
        rows={rows}
        columns={columns}
        filterMode="server"
        onFilterModelChange={handleFilterChange}
        pagination
        page={page.page}
        pageSize={PAGE_SIZE}
        paginationMode="server"
        rowCount={rowCount}
        rowHeight={35}
        onPageChange={debounce((params) => handlePageChange(params), 200)}
        onRowSelected={handleRowSelected}
        loading={loading}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          toolbar: { title, rowSelected, rowToolbarLinks },
        }}
      />
    </div>
  );
};
